import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import variants from '@styled-system/variant';
import Markdown from '@glowforge/gf-styled/src/components/markdown';
import css from '@styled-system/css';
import {
  LinkBaseStyles,
  linkVariants,
} from '@glowforge/gf-styled/src/components/link/link-base';

const UnstyledText = styled('div')`
  white-space: pre-wrap;

  font-size: var(--font-size-min);
  @media ${(props) => props.theme.queries.desktopAndUp} {
    font-size: var(--font-size-max);
  }
  @supports (font-size: clamp(1rem, 1rem, 1rem)) {
    font-size: clamp(
      var(--font-size-min, 1rem),
      calc(1rem + var(--font-size-target, 2vw)),
      var(--font-size-max, 1.3rem)
    );
  }

  ${(props) => (props.$textAlign ? `text-align: ${props.$textAlign};` : '')};
  ${(props) => (props.$color ? `color: ${props.$color};` : '')};

  // Fix for safari bug, whereby resizing window doesn't update vw in calc().
  // https://stackoverflow.com/questions/63965489/safari-14-min-max-clamp-not-working-with-vw-and-px-values
  // N.B.: To use this trick in SCSS/SASS/Less, use 0.000001vh;
  min-height: 0vh;
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  text-transform: var(--text-transform);
  line-height: var(--line-height, 1);
  letter-spacing: var(--letter-spacing);
  @media ${(props) => props.theme.queries.desktopAndUp} {
    letter-spacing: var(--letter-spacing-desktop, var(--letter-spacing));
  }

  ${variants({
    variants: {
      title: {
        '--font-family': '"Space Grotesk", sans-serif',
        '--font-size-min': `${51 / 16}rem`,
        '--font-size-max': `${85 / 16}rem`,
        '--font-size-target': '5.5vw',
        '--font-weight': '500',
        '--text-transform': 'none',
        '--letter-spacing-desktop': '-4.25px',
        '--letter-spacing': '-2.55px',
        '--line-height': '1',
      },
      heading: {
        '--font-family': '"Space Grotesk", sans-serif',
        '--font-size-min': `${26 / 16}rem`,
        '--font-size-max': `${38 / 16}rem`,
        '--font-weight': '500',
        '--text-transform': 'none',
        '--letter-spacing': '-1.3px',
        '--letter-spacing-desktop': '-1.9px',
        '--line-height': '1.2',
      },
      subheading: {
        '--font-family': '"Space Grotesk", sans-serif',
        '--font-size-min': `${16 / 16}rem`, // TODO': get actual design system font sizes.
        '--font-size-max': `${22 / 16}rem`, // TODO': get actual design system font sizes.
        '--font-weight': '500',
        '--text-transform': 'none',
        '--letter-spacing': '-0.4px',
        '--letter-spacing-desktop': '-0.55px',
        '--line-height': '1.45',
      },
      header4: {
        '--font-family': '"Space Grotesk", sans-serif',
        '--font-size-min': '1rem', // TODO': get actual design system font sizes.
        '--font-size-max': '1.5rem', // TODO': get actual design system font sizes.
        '--font-weight': '600',
        '--text-transform': 'none',
      },
      caption: {
        '--font-family': '"Space Grotesk", sans-serif',
        '--font-size-min': `${14 / 16}rem`, // TODO': get actual design system font sizes.
        '--font-size-max': `${14 / 16}rem`, // TODO': get actual design system font sizes.
        '--font-weight': '600',
        '--text-transform': 'uppercase',
      },
      paragraph: {
        '--font-family': '"Exo 2", sans-serif',
        '--font-size-min': `${17 / 16}rem`, // TODO': get actual design system font sizes.
        '--font-size-max': `${17 / 16}rem`, // TODO': get actual design system font sizes.
        '--font-weight': '400',
        '--text-transform': 'none',
        '--letter-spacing': '-0.08px',
        '--letter-spacing-desktop': '-0.09px',
        '--line-height': '1.5',
      },
    },
  })}
`;

const LinkWrapper = styled('a')`
  ${LinkBaseStyles}
  ${(props) => linkVariants(props)({ variant: 'body' })}
`;

const Text = ({
  as: As,
  asMarkdown,
  attributes,
  color,
  link = null,
  newWindow,
  text,
  textAlign,
  variant,
}) => {
  const theme = useTheme();

  const textStyle = {};
  textStyle.color =
    color && typeof color === 'string' && color !== 'none'
      ? css({
          color,
        })(theme)?.color ?? undefined
      : undefined;

  textStyle.textAlign =
    textAlign && typeof textAlign === 'string' ? textAlign : undefined;

  const ElementType = useCallback(({children, ...props}) => (
    <As {...props} {...(!link && attributes)}>{children}</As>
  ), [attributes, link]);

  const LinkElementType = useCallback(({children, ...props}) => (
    <a {...props} {...attributes}>{children}</a>
  ), [attributes]);

  const Component = asMarkdown ? (
    <Markdown
      source={text}
      element='span'
      {...(!link && attributes)}
      $color={textStyle.color}
      $textAlign={textStyle.textAlign}
    />
  ) : (
    <UnstyledText
      as={ElementType}
      variant={variant}
      $color={textStyle.color}
      $textAlign={textStyle.textAlign}>
      {text}
    </UnstyledText>
  );

  if (link) {
    let linkDetails = {};
    if (newWindow) {
      linkDetails = {
        rel: 'noreferrer',
        target: '_blank',
      };
    }

    return (
      <LinkWrapper
        as={LinkElementType}
        href={link}
        {...linkDetails}
        style={textStyle}>
        {Component}
      </LinkWrapper>
    );
  }

  return Component;
};

Text.propTypes = {
  as: PropTypes.string,
  asMarkdown: PropTypes.bool,
  attributes: PropTypes.shape({}),
  color: PropTypes.string,
  link: PropTypes.string,
  newWindow: PropTypes.bool,
  text: PropTypes.string,
  textAlign: PropTypes.string,
  variant: PropTypes.string,
};
Text.defaultProps = {
  as: 'p',
  asMarkdown: false,
  attributes: {},
  color: null,
  link: null,
  newWindow: null,
  text: null,
  textAlign: null,
  variant: 'paragraph',
};

export default Text;
