const defaultCard = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: 'Box',
  },
  children: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Image',
        options: {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb38cbf61e22148aaad438fbf569e7829?width=500',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
      },
    },
  ],
};

const textConfig = {
  name: 'Glowforge:Carousel',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F8fbbb3351b2945b1a5299f15706834df?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'scrollStepMultiply',
      type: 'number',
      defaultValue: 1,
    },
    {
      name: 'hideNavigationArrowsMobile',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'isBlog',
      type: 'boolean',
      friendlyName: 'Is Blog',
      defaultValue: false
    },
    {
      name: 'activateAdvancedOptions',
      type: 'boolean',
      onChange: (options) => {
        // Remove the advanced carousel slides.
        // They are nested components that are not available as children.
        // Normal carousel renders only child elements of the component.
        const isDisabled = options.get('activateAdvancedOptions') === false;
        if (isDisabled && options.get('slides').length > 0) {
          options.set('slides', []);
        }
      },
      defaultValue: false,
    },
    {
      name: 'slides',
      showIf: (options) => options.get('activateAdvancedOptions') === true,
      type: 'list',
      subFields: [
        {
          name: 'delay',
          type: 'number',
          helperText: 'Set in seconds.',
          defaultValue: 0,
        },
        {
          name: 'content',
          type: 'blocks',
          hideFromUI: true,
          defaultValue: [defaultCard],
        },
      ],
    },
    {
      name: 'options',
      type: 'object',
      showIf: (options) => options.get('activateAdvancedOptions') === true,
      defaultValue: {
        initialSlide: {
          desktop: 0,
          tablet: 0,
          mobile: 0,
        },
        slidesPerView: {
          desktop: 2,
          tablet: 2,
          mobile: 2,
        },
        spaceBetween: {
          desktop: 5,
          tablet: 5,
          mobile: 5,
        },
        speed: 400,
        effect: 'Slide',
      },
      subFields: [
        {
          name: 'toggleActiveCardContent',
          type: 'boolean',
          defaultValue: true,
          helperText: 'Show content of the active card.',
        },
        {
          name: 'slidesPerView',
          type: 'object',
          subFields: [
            {
              name: 'desktop',
              type: 'number',
              required: true,
            },
            {
              name: 'tablet',
              type: 'number',
              required: true,
            },
            {
              name: 'mobile',
              type: 'number',
              required: true,
            },
          ],
        },
        {
          name: 'spaceBetween',
          type: 'object',
          subFields: [
            {
              name: 'desktop',
              type: 'number',
            },
            {
              name: 'tablet',
              type: 'number',
            },
            {
              name: 'mobile',
              type: 'number',
            },
          ],
        },
        {
          name: 'initialSlide',
          type: 'object',
          subFields: [
            {
              name: 'desktop',
              type: 'number',
            },
            {
              name: 'tablet',
              type: 'number',
            },
            {
              name: 'mobile',
              type: 'number',
            },
          ],
        },
        {
          name: 'speed',
          type: 'number',
          defaultValue: 1,
          helperText: 'Speed in ms. 1 seconds <=> 1000',
        },
        {
          name: 'effect',
          type: 'string',
          enum: ['Slide', 'Coverflow', 'Flip', 'Cards'],
        },
        {
          name: 'autoplay',
          type: 'boolean',
        },
        {
          name: 'loop',
          type: 'boolean',
        },
        {
          name: 'navigation',
          type: 'boolean',
        },
        {
          name: 'pagination',
          type: 'boolean',
        },
      ],
    },
  ],
  defaultChildren: [defaultCard, defaultCard],
};

export default textConfig;
