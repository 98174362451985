import {
  wistiaRegex
} from '../util/wistia-shared'


export default {
  name: 'Glowforge:Wistia',
  image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Ffbabd804e9cf49c2a9d1c87bca17ddb7?quality=60&width=200&height=200',
  inputs: [
    {
      friendlyName: 'Wistia Embed',
      helperText: 'Copy the url from the Wistia. docs: https://glowforge.atlassian.net/wiki/spaces/SW/pages/2949021987/Wistia+Embed',
      name: 'wistiaLink',
      type: 'string',
      defaultValue: '',
      regex: {
        // pattern to test, like "^\/[a-z]$" 
        pattern: wistiaRegex,
        // flags for the RegExp constructor, e.g. "gi"  */
        options: "g",
        // message to display to end-users if the regex fails
        message: "You must use a media or embed URL from wistia"
      },
    },
    {
      friendlyName: 'Aspect Ratio',
      helperText: 'What is the videos aspect ratio? 100% makes it a cover video.',
      name: 'aspectratio',
      type: 'string',
      enum: ['16:9', '1:1', 'vertical', '100%'],
      defaultValue: '16:9'
    },
    {
      name: 'silentAutoPlay',
      type: 'boolean',
      helperText: 'If on, will embed the video inline for silent autoplay. Use judicially!',
      defaultValue: false,
      advanced: true,
    },
    {
      name: 'noInteract',
      type: 'boolean',
      helperText: 'If on, will use CSS to cover up Wistia video and prevent all user interaction',
      defaultValue: true,
      showIf: (options) => options.get('silentAutoPlay'),
      advanced: true,
    },
  ],
}