import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import variants from '@styled-system/variant';
import { useRouter } from 'next/navigation'
import SVGIcon from './icon';
import BuilderModalContext from '../modal/builderModalContext';
import { ACTIONS_ENUM } from './button.config';
import { addIdAttribute } from '../util/shared-configs';

const ButtonBase = styled('button')`
display: flex;
align-items: center;
justify-content: center;
box-shadow: ${(props) =>
  props.$noMobileBoxShadow ? '' : props.theme.effects.boxShadow.button};
font-family: 'Space Grotesk', sans-serif;
color: var(--color);
background-color: var(--background-color);
border: 0.125rem solid var(--border-color);
border-radius: 10em;
padding: 0.375rem 1.125rem;
text-decoration: unset;
white-space: nowrap;

&:hover {
  background-color: var(--hover-bg-color);
  border: 0.125rem solid var(--hover-border-color);
}

@media ${(props) => props.theme.queries.tabletAndUp} {
  box-shadow: none;
  justify-content: flex-start;

  &:hover {
    color: var(--color);
    box-shadow: ${(props) => props.theme.effects.boxShadow.button};
    background-color: var(--hover-bg-color);
    border-color: var(--hover-border-color);
  }
}

@media ${(props) => props.theme.queries.desktopAndUp} {
  width: fit-content;
}

${(props) =>
  variants({
    variants: {
      primary: {
        '--color': props.theme.colors.white,
        '--background-color': props.theme.colors.magic.buttonBase,
        '--hover-bg-color': props.theme.colors.magic.base,
        '--border-color': props.theme.colors.magic.buttonBase,
        '--hover-border-color': props.theme.colors.magic.base,
      },
      ghost: {
        '--color': props.theme.colors.white,
        '--background-color': 'transparent',
        '--hover-bg-color': props.theme.colors.magic.base,
        '--border-color': props.theme.colors.white,
        '--hover-border-color': props.theme.colors.magic.base,
      },
      'ghost-blue': {
        '--color': props.theme.colors.magic.base,
        '--background-color': 'transparent',
        '--hover-bg-color': 'transparent',
        '--border-color': props.theme.colors.magic.base,
        '--hover-border-color': props.theme.colors.magic.base,
      },
    },
  })}
`;

const Button = ({
  attributes: { onClick, ...attributes },
  action,
  addIcon,
  chooseCommonIcon,
  customIcon,
  dataTestId,
  iconSize,
  link,
  noMobileBoxShadow,
  openLinkInNewTab,
  text,
  variant,
  dotcomInternalNavigation,
}) => {
  // Determine what icon should show up
  // Setting it to favor the custom icon over a common one.
  const icon = addIcon ? customIcon || chooseCommonIcon : null;

  /**
   * For usage inside of a modal.
   */
  const { openModal, closeModal } = useContext(BuilderModalContext);

  const router = useRouter();
  // if has action, do that
  // if has onClick provided by builder.io, also call that.
  const onClickButton = useCallback(() => {
    if (action === ACTIONS_ENUM.closeModal) {
      closeModal();
    } else if (action === ACTIONS_ENUM.openModal) {
      openModal();
    }
    if (typeof onClick === 'function') onClick();
    // eslint-disable-next-line
  }, [action, onClick]);

  const onLinkNavigation = useCallback((e) => {
    if (dotcomInternalNavigation) {
      router.push(link);
      e.preventDefault();
      return false;
    }
    return true;
  }, [router, link, dotcomInternalNavigation]);

  const standalone = text?.length === 0 ?? true;

  if (link) {
    const newTab = openLinkInNewTab
      ? {
          target: '_blank',
          rel: 'noreferrer',
        }
      : {};
    return (
      <ButtonBase
        variant={variant}
        as='a'
        href={link}
        onClick={onLinkNavigation}
        $noMobileBoxShadow={noMobileBoxShadow}
        {...addIdAttribute(dataTestId)}
        {...newTab}
        {...attributes}>
        {text}
        {icon && (
          <SVGIcon
            standalone={standalone}
            url={icon}
            height={iconSize}
            width={iconSize}
          />
        )}
      </ButtonBase>
    );
  }

  return (
    <ButtonBase
      $noMobileBoxShadow={noMobileBoxShadow}
      variant={variant}
      onClick={onClickButton}
      {...addIdAttribute(dataTestId)}
      {...attributes}>
      {text}
      {icon && (
        <SVGIcon
          standalone={standalone}
          url={icon}
          height={iconSize}
          width={iconSize}
        />
      )}
    </ButtonBase>
  );
};

Button.propTypes = {
  attributes: PropTypes.shape({
    onClick: PropTypes.func,
  }).isRequired,
  action: PropTypes.oneOf(Object.values(ACTIONS_ENUM)),
  addIcon: PropTypes.bool,
  chooseCommonIcon: PropTypes.string,
  customIcon: PropTypes.string,
  dataTestId: PropTypes.string,
  iconSize: PropTypes.number,
  link: PropTypes.string,
  openLinkInNewTab: PropTypes.bool,
  text: PropTypes.string,
  variant: PropTypes.string.isRequired,
  noMobileBoxShadow: PropTypes.bool,
  dotcomInternalNavigation: PropTypes.bool,
};

Button.defaultProps = {
  action: null,
  addIcon: false,
  chooseCommonIcon: null,
  customIcon: null,
  dataTestId: null,
  iconSize: 24,
  link: null,
  openLinkInNewTab: false,
  text: null,
  noMobileBoxShadow: false,
  dotcomInternalNavigation: false,
};

export default Button;
