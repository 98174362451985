import {
  NavigationIconWrapper,
  CategoryText,
  IconTextLink,
} from '../../blog-categories-navigation.styles';
import { Image as BuilderImage } from '@builder.io/react';

const IconText = ({ category, homeLink }) => {
  const linkInfo = homeLink ? homeLink : category;
  return (
    <IconTextLink href={homeLink ? homeLink.link : `/blog/categories/${category?.slug}`} className='navigation-link-wrapper'>
      {linkInfo?.image && (
        <NavigationIconWrapper>
          <BuilderImage
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            lazy='false'
            image={linkInfo?.image}
          />
        </NavigationIconWrapper>
      )}
      <CategoryText>{linkInfo?.name}</CategoryText>
    </IconTextLink>
  );
};

export default IconText;
