import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image as BuilderImage } from '@builder.io/react';
import useMediaQuery from '@glowforge/gf-styled/src/hooks/useMediaQuery';
import { QUERIES } from '@glowforge/gf-styled/src/theme/constants';
import { addIdAttribute } from '../util/shared-configs';

import {
  Wrapper,
  ButtonsContainer,
  ButtonsWrapper,
  ImgTextWrapper,
  ButtonWrapper,
  Button,
  TitleDescriptionWrapper,
  ImgContainer,
  Title,
  DescriptionDesktop,
  DescriptionMobile,
  ImgTextContainer,
  SelectFeatures,
} from './explore-features.styles';

const ExploreFeatures = ({ features = [], selectFeatures, dataTestId }) => {
  const [selectedFeature, setSelectedFeature] = useState(
    features.length > 0 && features[0],
  );
  const { descriptionDesktop, descriptionMobile, image, name } =
    selectedFeature;

  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const sectionRef = useRef(null);

  const isDesktopScreen = useMediaQuery(QUERIES.desktopAndUp);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsSectionVisible((state) => (!state ? entry.isIntersecting : true));
      },
      {
        threshold: 0.1,
      },
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Wrapper
      className='explore-features-wrapper'
      ref={sectionRef}
      {...addIdAttribute(dataTestId)}>
      <ButtonsContainer>
        <ButtonsWrapper className='features-btns-wrapper'>
          {features?.map((feature, index) => {
            return (
              <ButtonWrapper
                isSectionVisible={isSectionVisible}
                index={index}
                key={feature.name + index}>
                <Button
                  active={feature.name === name}
                  onClick={() => setSelectedFeature(feature)}>
                  {feature.name}
                </Button>
              </ButtonWrapper>
            );
          })}
        </ButtonsWrapper>
      </ButtonsContainer>
      {selectFeatures && (
        <SelectFeatures className='features-select-features'>
          {selectFeatures}
        </SelectFeatures>
      )}
      <ImgTextContainer isSectionVisible={isSectionVisible}>
        <ImgTextWrapper
          className='features-img-text-wrapper'
          key={name + image}
          {...(isDesktopScreen && {
            initial: { x: 100, opacity: 0 },
            animate: { x: 0, opacity: 1 },
            exit: { x: -100, opacity: 0 },
            transition: { duration: 0.4 },
          })}>
          {image && (
            <ImgContainer
              {...(!isDesktopScreen && {
                initial: { opacity: 0 },
                animate: { opacity: 1 },
                transition: { duration: 0.5 },
              })}>
              <BuilderImage
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                lazy='false'
                image={image}
              />
            </ImgContainer>
          )}
          <TitleDescriptionWrapper>
            {name && <Title className='features-title'>{name}</Title>}
            {descriptionDesktop && (
              <DescriptionDesktop className='features-description-desktop'>
                {descriptionDesktop}
              </DescriptionDesktop>
            )}
            {descriptionMobile && (
              <DescriptionMobile className='features-description-mobile'>
                {descriptionMobile}
              </DescriptionMobile>
            )}
          </TitleDescriptionWrapper>
        </ImgTextWrapper>
      </ImgTextContainer>
    </Wrapper>
  );
};

ExploreFeatures.propTypes = {
  attributes: PropTypes.shape({}),
  selectFeatures: PropTypes.string,
  dataTestId: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      descriptionDesktop: PropTypes.string,
      descriptionMobile: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
};

ExploreFeatures.defaultProps = {
  attributes: {},
  features: [],
  selectFeatures: '',
  name: '',
  descriptionDesktop: '',
  descriptionMobile: '',
  image: '',
  dataTestId: null,
};

export default ExploreFeatures;
