import { withChildren } from '@builder.io/react/lite';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import WistiaModalEmbed, { WistiaModalGlobalStyle } from './wistia-modal.styles';
import {
  parseWistiaLink,
  registerEmbed
} from '../util/wistia-shared'
import EditorError from '../util/editor-error';

export const WistiaModal = ({ children, wistiaLink, builderBlock }) => {
  const [videoReady, setVideoReady] = useState(false)
  const { id } = builderBlock;
  const mediaId = parseWistiaLink(wistiaLink);
  useEffect(() => {
    if (mediaId) {
      registerEmbed(mediaId, {
        popover: true,
        popoverContent: 'link',
        onReady: () => {
          setVideoReady(true)
        }
      })
    }
  }, [mediaId]);

  return mediaId ?
    <>
      <Script id={`wistia-${mediaId}`} src={`https://fast.wistia.com/embed/medias/${mediaId}.jsonp`} strategy='afterInteractive'/>
      <Script id='wistia-ev1'  src='https://fast.wistia.com/assets/external/E-v1.js' strategy='afterInteractive'/>
      <WistiaModalGlobalStyle />
      <div suppressHydrationWarning>
        <WistiaModalEmbed
          id={id}
          style={{opacity: videoReady ? 1 : 0}}
          className={`wistia_embed wistia_async_${mediaId} popover=true popoverContent=link`}>
            {children}
        </WistiaModalEmbed>
      </div>
    </>
    :
    <EditorError>
      <p>Could not parse mediaId from Wistia url</p>
    </EditorError>
};

WistiaModal.propTypes = {
  children: PropTypes.node,
  wistiaLink: PropTypes.string,
  builderBlock: PropTypes.shape({
    id: PropTypes.string,
  }),
};

WistiaModal.defaultProps = {
  children: null,
  wistiaLink: '',
  builderBlock: {
    id: '',
  },
};

export default withChildren(WistiaModal);
