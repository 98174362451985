import { allowedColors } from '@glowforge/gf-styled/src/theme/constants/colors';

const textConfig = {
  name: 'Glowforge:Text',
  noWrap: true,
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F567e45aac5c5413091ddd7794a7cdf9a?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'text',
      type: 'longText',
      defaultValue: 'Text',
    },
    {
      name: 'variant',
      type: 'string',
      defaultValue: 'paragraph',
      enum: [
        'title',
        'heading',
        'subheading',
        'header4',
        'caption',
        'paragraph',
      ],
    },
    {
      name: 'color',
      type: 'string',
      defaultValue: 'black',
      enum: [...allowedColors, 'none'],
      helperText: `color applies to all device sizes,
        and is intended to provide a simple way to use brand colors.
        For more controls, and device specific overrides, use the styles tab`
    },
    {
      name: 'textAlign',
      type: 'string',
      defaultValue: 'left',
      enum: ['left', 'center', 'right', 'initial'],
      helperText: `textAlign applies to all device sizes,
        and is intended to provide a simple way to align text.
        For more controls, and device specific overrides, use the styles tab`,
    },
    {
      name: 'asMarkdown',
      type: 'boolean',
      defaultValue: false,
      description:
        'Renders text as Markdown if true. Allows for embedded links, italics, etc.',
    },
    {
      name: 'as',
      type: 'string',
      defaultValue: 'p',
      enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span'],
    },
    {
      name: 'link',
      type: 'url',
      defaultValue: null,
    },
    {
      name: 'newWindow',
      type: 'boolean',
      defaultValue: false,
    },
  ],
};

export default textConfig;
