import  { useState } from 'react';
import {
  NavigationIconWrapper,
  NavigationLinkWrapper,
  CategoryText,
  DropdownIcon,
  MobileRowContainer,
  IconTextLink,
} from '../../blog-categories-navigation.styles';
import { Image as BuilderImage } from '@builder.io/react';
import SVG from '@glowforge/gf-styled/src/components/svg/svg';
import Dropdown from './inner-dropdown';

const InnerRowDropdown = ({ category, isSubcategories }) => {
  const [opened, setOpened] = useState(false);
  const handleClick = () => isSubcategories ? setOpened((state) => !state) : null;

  
  return (
    <MobileRowContainer
      onClick={handleClick}
      opened={opened}
      className='mobile-inner-row-container'>
      <NavigationLinkWrapper>
        {category?.slug && category?.name && (
          <IconTextLink href={`/blog/categories/${category?.slug}`} onClick={(e) => e.stopPropagation()} className="navigation-link-wrapper">
            {category?.image && (
              <NavigationIconWrapper>
                <BuilderImage
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                  lazy='false'
                  image={category?.image}
                />
              </NavigationIconWrapper>
            )}
            <CategoryText>{category?.name}</CategoryText>
          </IconTextLink>
        )}

        {isSubcategories && (
          <DropdownIcon className='icon'>
            <SVG
              id='chevron-down'
              fill='#000'
              stroke='#000'
              width={10}
              height={10}
            />
          </DropdownIcon>
        )}
      </NavigationLinkWrapper>

      {category?.subcategories?.length > 0 && (
        <Dropdown
          opened={opened}
          subcategories={category?.subcategories}
          className='mobile-inner-dropdown'
        />
      )}
    </MobileRowContainer>
  );
};

export default InnerRowDropdown;
