import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import {
  WistiaResponsivePadding169,
  WistiaResponsivePadding1to1,
  WistiaResponsivePaddingVertical,
  WistiaReponsivePadding100,
  WistiaResponsiveWrapper,
  WistiaEmbed,
  WistiaSwatch,
  WistiaImg,
} from './wistia.styles';

import { parseWistiaLink } from '../util/wistia-shared';
import EditorError from '../util/editor-error';

export const Wistia = ({
  aspectratio,
  builderBlock,
  noInteract,
  silentAutoPlay,
  wistiaLink,
}) => {
  let WistiaResponsivePadding = WistiaResponsivePadding169;
  // Aspect Ratio switch
  switch (aspectratio) {
    case '1:1':
      WistiaResponsivePadding = WistiaResponsivePadding1to1;
      break;
    case 'vertical':
      WistiaResponsivePadding = WistiaResponsivePaddingVertical;
      break;
    case '100%':
      WistiaResponsivePadding = WistiaReponsivePadding100;
      break;
    case '16:9':
    default:
      WistiaResponsivePadding = WistiaResponsivePadding169;
      break;
  }

  const { id } = builderBlock;
  const mediaId = parseWistiaLink(wistiaLink);

  const [opacity, setOpacity] = useState(0);

  return mediaId ? (
    <>
      <Script
        id={`wistia-${mediaId}`}
        src={`https://fast.wistia.com/embed/medias/${mediaId}.jsonp`}
        strategy='afterInteractive'
      />
      <Script
        id='wistia-ev1'
        src='https://fast.wistia.com/assets/external/E-v1.js'
        strategy='afterInteractive'
      />
      <WistiaResponsivePadding id={id} className='wistia_responsive_padding'>
        <WistiaResponsiveWrapper
          className='wistia_responsive_wrapper'
          silentAutoPlay={silentAutoPlay}
          noInteract={noInteract}>
          <WistiaEmbed
            className={`
                wistia_embed wistia_async_${mediaId}
                ${
                  silentAutoPlay
                    ? `
                      controlsVisibleOnLoad=false
                      endVideoBehavior=loop
                      fitStrategy=cover
                      fullscreenOnRotateToLandscape=false
                      playbackRateControl=false
                      playbar=false
                      playsinline=true
                      settingsControl=false
                      silentAutoPlay=true
                      volume=0
                      volumeControl=false
                      `
                    : 'videoFoam=true'
                }
              `}>
            <WistiaSwatch
              className='wistia_swatch'
              style={{ opacity }}>
              <WistiaImg
                src={`https://fast.wistia.com/embed/medias/${mediaId}/swatch`}
                alt=''
                aria-hidden='true'
                cover={aspectratio === '100%'}
                onLoad={() => {
                  setOpacity(1);
                }}
              />
            </WistiaSwatch>
          </WistiaEmbed>
        </WistiaResponsiveWrapper>
      </WistiaResponsivePadding>
    </>
  ) : (
    <EditorError>
      <p>Could not parse mediaId from Wistia url</p>
    </EditorError>
  );
};

Wistia.propTypes = {
  aspectratio: PropTypes.string,
  wistiaLink: PropTypes.string,
  builderBlock: PropTypes.shape({
    id: PropTypes.string,
  }),
  silentAutoPlay: PropTypes.bool,
  noInteract: PropTypes.bool,
};

Wistia.defaultProps = {
  aspectratio: '16:9',
  wistiaLink: '',
  builderBlock: {
    id: '',
  },
  silentAutoPlay: false,
  noInteract: false,
};

export default Wistia;
