import PropTypes from 'prop-types';
import DesktopNav from './components/desktop-nav/desktop-navigation';
import MobileNav from './components/mobile-nav/mobile-navigation';

const BlogNavigation = ({ ...props }) => {
  const { homeLink, mobileDropdownOpenerText } =  props || {};
  const categories = props?.categories.map(
    ({ category, subcategories, image }) => {
      return {
        name: category?.value?.data?.name,
        slug: category?.value?.data?.slug,
        image,
        subcategories,
      };
    },
  );
  return (
    <>
      {categories?.length > 0 && (
        <>
          <DesktopNav homeLink={homeLink} categories={categories} />
          <MobileNav homeLink={homeLink} categories={categories} mobileDropdownOpenerText={mobileDropdownOpenerText} />
        </>
      )}
    </>
  );
};

BlogNavigation.propTypes = {
  homeLink: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
};

BlogNavigation.defaultProps = {
  homeLink: '',
  categories: [],
};

export default BlogNavigation;
