import { CountryCodes } from '../constants';
import { fetchApi, buildFetchContext, fetchWithoutToken } from '../util/fetch-api';
import fetchCountryCode from './geolocation';
import { getAllFlagsState } from './feature-flags';


/**
 * Attempts to fetch user.
 * Sends a request to server with authentication to validate against.
 * @param {Object} [options] Options to pass to fetch()
 * @returns {Promise} Always resolves. Will either resolve with
 * js object if user session, or null if no user session.
 */
export async function fetchUser(options = {}) {
  let fetchImpl = fetchApi;
  if (options.context) {
    const { fetchWithToken } = buildFetchContext(options.context);
    fetchImpl = fetchWithToken;
  }

  try {
    const json = await fetchImpl('/user/v1/profile', options);
    return { ...json, is_admin: (json.roles && json.roles.length > 0) };
  } catch (e) {
    return null;
  }
}

/**
 * Creates a new user.
 * Sends a POST request to server with email and targeting information.
 * @param {Object} userData - The user data to be sent.
 * @param {string} userData.email - The email of the user.
 * @param {Object} userData.targeting - The targeting information for the user.
 * @param {Object} [options] - Options to pass to fetch()
 * @returns {Promise} Resolves with the created user data or rejects
 *                    with an error.
 */
export async function createUser(userData, options = {}) {
  try {
    const { email, targeting } = userData;
    const response = await fetchWithoutToken('/user/v1/profile', {
      ...options,
      method: 'POST',
      body: JSON.stringify({ email, targeting }),
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
    const json = await response.json();
    return {
      data: { ...json },
      status: response.status
    };
  } catch (e) {
    return {
      data: null,
      status: e.response?.status || 500,
      error: `Failed to create user: ${e.message}`
    };
  }
}


/**
 * Fetch a list of products
 *  This has now been modified to get products for different countries
 * @param {Object} context The context object from getInitialProps
 * @param {Object} [options]
 * @param {String} options.forceCountryCode Don't fetch country code,
 * just force one
 * @returns {Promise<Object>} Returns the promise to get you the GF products
 */
export const fetchProducts = async (context, options = { forceCountryCode: null }) => {
  try {
    const { fetchWithToken } = buildFetchContext(context);
    const flagStates = await getAllFlagsState();
    const { 'space-monkeys': ENABLED_COUNTRIES } = flagStates;
    // Get the requested country code for this session from cloudfront headers
    // (client or server-side)
    let countryCode = options.forceCountryCode ?
      options.forceCountryCode : await fetchCountryCode(context);
    if (!ENABLED_COUNTRIES?.includes(countryCode)) {
      countryCode = CountryCodes.US;
    }
    const json = await fetchWithToken(
      `/ecom/v1/shopify_products?country=${countryCode}`, { includeAuth: false });
    return json.products;
  } catch (e) {
    console.log(e);
    return {};
  }
};
