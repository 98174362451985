import { allowedColors } from '@glowforge/gf-styled/src/theme/constants/colors';
import { wistiaRegex } from '../util/wistia-shared';

export default {
  name: 'Glowforge:StaggeredCard',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fd7d52a4344024e43b916f0332f9b9165?quality=60&width=200&height=200',
  inputs: [
    {
      friendlyName: 'Right or Left Image',
      name: 'variant',
      type: 'string',
      enum: ['right-image', 'left-image'],
      defaultValue: 'right-image',
    },
    {
      friendlyName: 'Image',
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F6e6cca73e5d14cd99c6231c0ebd402db?quality=60',
    },
    {
      friendlyName: 'Wistia Modal (click on image)',
      helperText: 'Copy the url from the Wistia. ex: https://glowforge.wistia.com/medias/pv3pj3pvc7',
      name: 'wistiaLink',
      type: 'string',
      defaultValue: '',
      regex: {
        // pattern to test, like "^\/[a-z]$" 
        pattern: wistiaRegex,
        // flags for the RegExp constructor, e.g. "gi"  */
        options: "g",
        // message to display to end-users if the regex fails
        message: "You must use a media or embed URL from wistia"
      },
      showIf: (options) => options.get('image')
    },
    {
      friendlyName: 'Wistia Modal Button text',
      helperText: 'Play button text',
      name: 'wistiaButtonText',
      type: 'string',
      defaultValue: 'Watch Now',
      showIf: (options) => options.get('wistiaLink')
    },
    {
      friendlyName: 'Text Background Image',
      helperText: 'Used as a background texture for the Text card portion.',
      name: 'backgroundImage',
      type: 'string',
      enum: [
        'magic dots',
        'wood',
        'honeycomb',
      ],
      defaultValue: 'magic dots'
    },
    {
      friendlyName: 'Background Color',
      name: 'backgroundColor',
      type: 'string',
      enum: allowedColors,
      defaultValue: 'magic.dark',
    },
    {
      friendlyName: 'Display Quote?',
      name: 'showQuote',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'The quote icon color is the lightest variation of the chosen Background Color.',
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Box',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'heading',
              as: 'p',
              text: 'Heading Text',
            },
          },
          responsiveStyles: {
            large: {
              color: '#26B8CE',
              paddingBottom: '20px',
              textAlign: 'left',
            }
          },
        },
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'paragraph',
              as: 'p',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae elementum curabitur vitae nunc sed.',
            },
          },
          responsiveStyles: {
            large: {
              textAlign: 'left',
            },
          },
        },
      ],
    },
  ],
};
