import styled from 'styled-components'
import variant from '@styled-system/variant';
import css from '@styled-system/css';

import GridContainer from '@glowforge/gf-styled/src/components/grid-container/grid-container';
import { Title } from '@glowforge/gf-styled/src/components/typography/Typography';
import Markdown from '@glowforge/gf-styled/src/components/markdown';
import Button from '@glowforge/gf-styled/src/components/button/button-base'
import Link from '@glowforge/gf-styled/src/components/link/link-base'

export const Background = styled('div')`
  height: 75%;
  width: 50%;
  margin-left: -1rem; // account for padding on TextContainer in mobile.
  grid-column: main-start / main-end;
  grid-row: 1;
  align-self: stretch; // fill up vertical space.
  background-image: url(${props => props.backgroundImage}?w=800);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;

  @media ${props => props.theme.queries.desktopAndUp} {
    height: 87%;    
    background-image: url(${props => props.videoPresent ? 'none' : props.backgroundImage});
    margin-left: 0;
  }
`

export const Wrapper = styled(GridContainer)`
  width: 100%;
  grid-gap: 0;
  // make z-index isolated inside of wrapper
  isolation: isolate;
`;

export const TextContainer = styled(GridContainer)`
  color: ${props => props.theme.colors.white};
  position: relative;
  grid-column: var(--grid-column);
  --grid-column: col-start 1 / col-end 4;
  // content does not extend to edges
  padding: 0 1rem;
  min-height: ${props => props.minHeightMobile};
  display: grid;
  /* align-items: center; */

  ${props => css({
    backgroundColor: props.backgroundColor
  })}
  
  @media ${props => props.theme.queries.desktopAndUp} {
    grid-row: 1; // place on same row as image.
    width: 100%;
    margin-top: 50px;
    min-height: ${props => props.minHeightDesktop};;
    padding: 0;

    ${variant({
      prop: 'side', // don't collide with GridContainer's variant.
      variants: {
        'right-image': {
          '--grid-column': 'col-start 1 / col-end 7',
        },
        'left-image': {
          '--grid-column': 'col-start 6 / col-end 12',
        },
      },
    })}
  };
`;

export const FloatQuote = styled(Title)`
  
  position: absolute;
  font-size: 9rem;
  line-height: 0;
  top: 25px;
  z-index: 1;
  ${props => css({
    color: props.color
  })}
  @media ${props => props.theme.queries.desktopAndUp} {
    font-size: 11rem;
    grid-column: col-start 2 / col-end 2;
    left: -16px;
  }
`

/**
 * Wrapper for text content of text container.
 */
export const TextContentWrapper = styled('div')`
  grid-column: var(--grid-column);
  --grid-column: col-start 1 / col-end 4;
  grid-row: 1; // keep in same row as background textures.
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 42px 0;

  & ${Link}, ${Button} {
    align-self: stretch;
  }

  @media ${props => props.theme.queries.desktopAndUp} {
    & ${Link}, ${Button} {
      align-self: flex-start;
    }
    ${variant({
      variants: {
        'right-image': {
          '--grid-column': 'col-start 2 / col-end 9'
        },
        'left-image': {
          '--grid-column': 'col-start 3 / col-end 10'
        },
      }
    })}
  }
`;

export const BodyContentMarkdown = styled(Markdown)`
  padding: 16px 0;
`;

export const ImageContainer = styled('div')`
  position: relative;
  background-color: ${props => props.theme.colors.slate.base}; // default if no image is supplied
  max-height: 300px;
  grid-column: var(--grid-column);
  --grid-column: col-start 1 / col-end 4;

  @media ${props => props.theme.queries.desktopAndUp} {
    grid-row: 1; // place on same row as text.
    width: 100%;
    height: 400px;
    max-height: 400px;
    z-index: 1;
    ${variant({
      variants: {
        'right-image': {
          '--grid-column': 'col-start 7 / col-end 12',
        },
        'left-image': {
          '--grid-column': 'col-start 1 / col-end 6',
        },
      },
    })}
  };

  img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

// We're going old school
export const WistiaWrapper = styled('div')`
  position: absolute;
  top: 50%;
  left 50%;
  transform: translate(-50%, -50%);
`

export const WistiaPlayButton = styled('button')`
  cursor: pointer;
  background-color: #27B8CE;
  box-shadow: 0px 10px 15px #00000033;
  padding: 5px 10px;
  border: 2px solid #27B8CE;
  border-radius: 16px;
  opacity: 1;
  color: white;
  
  svg {
    vertical-align: sub;
  }
`
