import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// eslint-disable-next-line no-unused-vars
import { useRouter } from 'next/router';
import TopNav from '@glowforge/gf-styled/src/components/navigation/top-nav';
import { Builder, BuilderBlocks } from '@builder.io/react/lite';
import registerComponents from '@glowforge/builder-components/lib/register';
import useUser from '../../context/UserContext/useUser';
import { getNavbar } from '../../api/navbar';

const BuilderBlocksStyledWrapper = styled('div')`
  /* in builder preview, this prevents the "Add Block" button from overflowing the nav */
  .builder-blocks.no-blocks {
    min-height: auto;
  }
`;

registerComponents(Builder);

/**
 * NavbarV2 pulls data from builder.io. This allows for builder.io features
 * to be used with the nav: live editing, targeting, etc.
 * - Live editing is done at glowforge.com/b/navbar-preview route.
 * - Targeting is hooked up in api/getNavbar based on user state
 */
export default function NavbarV2(props) {
  const { user: fetchedUser, userFetchState } = useUser();
  const { pathname } = useRouter();
  /**
   * Utilizes component state because we fetch new navigation data clientside
   * if a user is logged in.
   */
  /* eslint-disable react/destructuring-assignment */
  const [
    {
      navItems,
      buttonSection,
      userNavItems,
      logoLink,
      previewUserLoggedIn,
      hideBuyButton,
    },
    setState,
  ] = useState({
    navItems: props.navItems,
    userNavItems: props.navItems,
    logoLink: props.logoLink,
    previewUserLoggedIn: props.previewUserLoggedIn,
    buttonSection: props.buttonSection,
    hideBuyButton: props.hideBuyButton,
  });

  /**
   * This effect is just used to update navbar while editing.
   * In _app.jsx, we use useSWR to fetch data. This causes a clientside
   * call to getNavbar, which builder knows to inject an override for the
   * currently edited data.
   * Because of this, we need to read the new props, and setState. If there is
   * a reason in production that this data might change via props, then reasess.
   */
  useEffect(() => {
    if (Builder.isEditing || Builder.isPreviewing) {
      setState({
        navItems: props.navItems,
        userNavItems: props.userNavItems,
        logoLink: props.logoLink,
        buttonSection: props.buttonSection,
        previewUserLoggedIn: props.previewUserLoggedIn,
        hideBuyButton: props.hideBuyButton,
      });
    }
  }, [
    props.buttonSection,
    props.logoLink,
    props.navItems,
    props.previewUserLoggedIn,
    props.userNavItems,
    props.hideBuyButton,
  ]);

  /**
   * We can have alternative navs based on user state.
   * Beacuse of CDN caching, we fetch User clientside.
   * So, only fetch a new navbar if the user is actually logged in.
   */
  useEffect(() => {
    async function fetchLoggedInNavbar(){
      const newNavProps = await getNavbar({
        loggedIn: true,
        /**
         * todo: Follow up to get machine state for Account w/ no glowforge
         * https://app.asana.com/0/1202772176817908/1202887414586565/f
         *
         * Setting as always true for parity with v1 nav where there is only
         * logged-out/logged-in functionality.
         */
        hasMachine: true,
      });

      if (
        userFetchState === 'pending' ||
        Object.entries(fetchedUser).length === 0
      ) {
        if (userFetchState !== 'pending') {
          window.friendbuyAPI = window.friendbuyAPI || [];
          const { friendbuyAPI } = window;

          (friendbuyAPI || []).push(['logout']);
          window.analytics.reset();
        }

        return;
      }

      // Need to make sure we at least have an email for logged in user
      if (window.analytics && fetchedUser && fetchedUser.email) {
        window.analytics.identify(fetchedUser.uuid, {
          email: fetchedUser.email,
          first_name: fetchedUser.first_name,
          last_name: fetchedUser.last_name,
          status: 'logged_in',
        });
      }
      setState(newNavProps);
    }

    fetchLoggedInNavbar();
  }, [fetchedUser, userFetchState]);

  const shouldStubUser =
    previewUserLoggedIn && (Builder.isEditing || Builder.isPreviewing);

  /* eslint-disable no-nested-ternary */
  /**
   * This check is for Editing/Previewing.
   * This `user` const is set as the fetchedUser unless
   * in the builder.io editor or preview env.
   */
  const user =
    Object.entries(fetchedUser).length > 0
      ? fetchedUser
      : shouldStubUser
      ? {
          email: 'test@glowforge.com',
          shopify_id: '2260850344024',
          first_name: '',
          last_name: '',
          display_name: 'Editor User',
          organization_name: null,
          phone: null,
          country_code: 'US',
          roles: [],
        }
      : {};

  /* eslint-enable no-nested-ternary */
  return (
    <TopNav
      user={user}
      userFetchState={userFetchState}
      buttonSection={
        !hideBuyButton ? (
          <BuilderBlocksStyledWrapper className='navbarButton'>
            <BuilderBlocks blocks={buttonSection} />
          </BuilderBlocksStyledWrapper>
        ) : null
      }
      routes={{
        primaryRoutes: navItems,
        userRoutes: userNavItems,
      }}
      activeLink={pathname}
      logoLink={logoLink}
    />
  );
}

NavbarV2.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({})),
  userNavItems: PropTypes.arrayOf(PropTypes.shape({})),
  logoLink: PropTypes.string,
  buttonSection: PropTypes.arrayOf(PropTypes.shape({})),
  previewUserLoggedIn: PropTypes.bool,
  hideBuyButton: PropTypes.bool,
};

NavbarV2.defaultProps = {
  navItems: [],
  userNavItems: [],
  logoLink: '#',
  buttonSection: [],
  previewUserLoggedIn: false,
  hideBuyButton: false,
};
