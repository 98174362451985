/* eslint-disable import/no-cycle */
/*
  Disabling no-cycle for this file because the getWidget and getSectionsArray 
  functions are used by widgets that are imported by this file.  This is a 
  module cycle, but not an issue because the dependency cycle is not synchronous
  The dependencies are used at runtime not at build/file parse time.
  https://railsware.com/blog/how-to-analyze-circular-dependencies-in-es6/
*/
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get'
import chain from 'lodash/chain'
import intersection from 'lodash/intersection'
import split from 'lodash/split'
import map from 'lodash/map'
import stubFalse from 'lodash/stubFalse'
import Markdown from '../markdown';
import MediaObject from './media-object';
import { getContentType } from '../../models/page';
import { WidgetModel } from '../../models/widget';
import { stringSubstitution } from './util';
import { stringSubstitutionsPropType, customWidgetsPropType } from '../common-prop-types';

/**
 * TODO: STORE-2847 Dynamic imports for widgets so we can codesplit.
 *
 * Sections is used to render an ordered piece of UI. It maps an array of 
 * objects with widget types to widgets. These widgets then extract Contentful 
 * data and map it to a React Component. This provides a layer of separation 
 * between the UI and Contentful. Widgets are a form of controller,
 * mapping a model to a view.
 *
 * Terminology:
 *  - component
 *    - React Component. Used for rendering UI, no knowledge of Contentful.
 *  - widget
 *    - Has knowledge of Contentful Content Models. Maps a Contentful
 *      Content Model to a piece of UI. Can contain sections and other widgets.
 *    - Is a Higher Order Component for providing components with props.
 *  - sections
 *    - An array of widgets.
 *  - custom widget
 *    - A special Contentful Content Model type, can contain unstructured 
 *      content made up of text, images, and other widgets. Used for one-off 
 *      pieces of UI.
 *      See ecomm-front-end/app/components/company/jobs-home.jsx for an example.
 *
 * Adding components:
 *  - In ui-common/components/, Create a simple component
 *    (no Contentful knowledge) that takes in props and renders UI.
 *  - If the component has a corresponding Content Model or needs data from 
 *    Contentful, create a widget in ui-common/widgets/
 *    - Add the component to the widgets map below.
 *  - If the component is a Custom Widget:
 *      Pass a customWidgets object/map to <Sections customWidgets={{...}} />
 *
 *      The customWidgets object should look like:
 *        customWidgets = {
 *         'widgetId-key-from-contentful': {
 *           component: CustomWidget,
 *           props, // props to be passed.
 *          },
 *        };
 *
 *      Where the keys are Widget Ids from Contentful.
 *
 */

/**
 * Mapping of widget types to Widgets.
 * Is populated via Widgets registering themselves with the registerWidget 
 * function
 */
const widgets = {};

/**
 * This factory allows us to avoid a circular dependency and widgets not 
 * being available soon enough. Register your widget like: 
 * `registerWidget('contentType', WidgetComponent)`
 * @param {String} contentType The content type in Contentful. Used as a key in 
 * the widgets map.
 * @param {Component} Widget React component / contentful widget for the 
 * content type.
 */
export function registerWidget(contentType, Widget) {
  widgets[contentType] = Widget;
}

/**
 * getWidget retrieves a widget for a given Contentful model.
 * @param {Object} model Contentful model for the widget
 * @param {*} customWidgets Mappings for any Custom Widgets
 * @param {*} stringSubstitutions See 
 * ui-common/components/contentful-views/util.js
 */
export const getWidget = (model, customWidgets, stringSubstitutions) => {
  const type = getContentType(model);
  const key = get(model, 'sys.id');
  const id = get(model, 'fields.id');
  const Widget = widgets[type];
  if (!Widget) {
    // if widget doesn't exist, you might have forgotten to register it.
    // see `registerWidget` above.
    console.warn(`gf-styled: widget ${type} not registered.`);
    return null;
  }
  return (
    <Widget
      id={id}
      key={key}
      model={model}
      customWidgets={customWidgets}
      stringSubstitutions={stringSubstitutions}
    />
  );
};

/* Classes styled in content-page.less */
const ALLOWED_BACKGROUND_CLASSES = [
  'greyFullWidth',
  'blueFullWidth',
  'darkBlueFullWidth',
  'blackBackground',
  'contentWide',
  'contentFull',
  'noMarginTop',
  'noMarginBottom',
  'hiddenSection',
  'hiddenDesktop',
  'hiddenMobile',
];

/**
 * getSectionsArray is used to map data to widgets in an ordered array. There 
 * are normal widgets that appear in the widgets mapping at the top of this file
 * ,and custom widgets that are provided by the consumer of this function.
 *
 * @param {Array}   sections Array of contentful data.
 * @param {Object}  [customWidgets] Object used to map custom widget data to 
 * widgets/components.
 * @param {Object|Array} [stringSubstitutions] Map of strings used to substitute
 *  in string templates
 * @returns {Component}
 */
export const getSectionsArray = (
  sections,
  customWidgets,
  stringSubstitutions,
  switchFunction = stubFalse,
) => (
  map(sections, (section, key) => {
    const widgetId = get(section, 'fields.id');
    const type = getContentType(section);
    let widget;
    switch (type) {
      // These components have been moved to the widget model.
      case 'heroBanner':
      case 'wistiaButton':
      case 'list':
      case 'socialCard':
      case 'buttonLink':
      case 'madLibForm':
      case 'madLibFlow': {
        widget = getWidget(section, customWidgets, stringSubstitutions);
        break;
      }
      /**
       * TODO: https://glowforge.atlassian.net/browse/STORE-2854
       * - These components need to be moved to the widget model
       * - Define a Section widget to provide a container for components.
       *    - We should not have special cases per component
       */
      case 'textString':
        widget = (
          <Markdown
            key={key}
            className={`paragraph ${get(section, 'fields.id')}`}
            source={stringSubstitution(get(section, 'fields.text'), stringSubstitutions)}
          />
        );
        break;
      case 'mediaObject':
        widget = (
          <MediaObject
            key={key}
            className={get(section, 'fields.id')}
            {...get(section, 'fields.media.fields')}
          />
        );
        break;
      case 'switch': {
        // Switch relies on the given switchFunction prop to decide which of
        // its variations to render.
        const {
          flagName,
          variations,
        } = get(section, 'fields');

        const recurse = variation => getSectionsArray(
          variation,
          customWidgets,
          stringSubstitutions,
          switchFunction,
        );

        return chain(variations)
          .filter(switchFunction(flagName))
          .map(variationSection => get(variationSection, 'fields.content'))
          .map(recurse)
          .value();
      }
      default: {
        const widgetType = get(section, 'fields.widgetType', '');
        const customWidget = customWidgets[widgetType];
        if (type === 'customWidget' && customWidget) {
          const Component = customWidget.component;
          widget = (
            <Component
              className={widgetType}
              model={new WidgetModel(section)}
              {...section.fields} // DEPRECATED: access widget data via model
              {...customWidget.props}
              stringSubstitutions={stringSubstitutions}
              key={key}
            />
          );
        } else {
          // TODO refactor to use widget model
          const Component = widgets[type];
          widget = Component
            ? (
              <Component
                className={widgetId}
                {...section.fields}
                customSections={customWidgets}
                stringSubstitutions={stringSubstitutions}
                key={key}
              />
            )
            : null;
        }
      }
    }
    const backgroundClass = get(section, 'fields.backgroundStyleClass');
    if (backgroundClass) {
      const classNames = intersection(
        split(backgroundClass, ',').map(b => b.trim()), ALLOWED_BACKGROUND_CLASSES,
      ).join(' ');
      return (
        <div 
          className={`${widgetId}-wrapper ${classNames} fullWidth`} 
          key={key}>
          {widget}
        </div>
        );
    }

    return widget;
  })
);

const Sections = ({
  className,
  sections,
  customSections,
  stringSubstitutions,
  switchFunction,
}) => (
  <div className={`Sections ${className}`}>
    {getSectionsArray(sections, customSections, stringSubstitutions, switchFunction)}
  </div>
);

Sections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  customSections: customWidgetsPropType,
  stringSubstitutions: stringSubstitutionsPropType,
  switchFunction: PropTypes.func,
};

Sections.defaultProps = {
  sections: null,
  className: '',
  customSections: {},
  stringSubstitutions: {},
  switchFunction: undefined,
};

export default Sections;
