import {
  DesktopNavigationLink,
  DesktopDropdownContainer,
  DesktopInnerDropdownContainer,
  CategoryText,
} from '../../blog-categories-navigation.styles';

const Dropdown = ({ category }) => {
  return (
    <DesktopDropdownContainer className='dropdown-container'>
      <DesktopInnerDropdownContainer className='dropdown-wrapper'>
        {category?.subcategories?.map(({ subcategory }) => {
          const { name, slug } = subcategory?.value?.data || {};
          return (
            name && slug && (
              <DesktopNavigationLink key={slug} href={`/blog/categories/${slug}`}>
                <CategoryText>{name}</CategoryText>
              </DesktopNavigationLink>
            ))
        })}
      </DesktopInnerDropdownContainer>
    </DesktopDropdownContainer>
  );
};
export default Dropdown;
