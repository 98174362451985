import React from 'react';
import PropTypes from 'prop-types';
import useCarousel from '@glowforge/gf-styled/src/hooks/useCarousel';
import SVG from '@glowforge/gf-styled/src/components/svg';
import Wistia from '../wistia';

import {
  Wrapper,
  CarouselWrapper,
  Subheading,
  FeaturesRow,
  Feature,
  Paragraph,
} from './feature-carousel.styles';

// Inspired by the news clips component
const FeatureCarousel = ({ delay, slides }) => {
  const { carouselProps, Carousel } = useCarousel(delay);
  const { activeIndex, setActiveIndex } = carouselProps;

  // TODO:  Video element to be placed when we're ready to roll with it
  return slides.length >= 1 ? (
    <Wrapper>
      <CarouselWrapper>
        <Carousel {...carouselProps} >
          {slides.map((slide) => (
            <React.Fragment key={slide.title}>
              <Wistia
                wistiaLink={slide.wistiaLink} 
                aspectratio='16:9'
                builderBlock={{id: slide.title}}
                silentAutoPlay
              />
              <Subheading>{slide.description}</Subheading>
            </React.Fragment>
          ))}
        </Carousel>
      </CarouselWrapper>

      <FeaturesRow>
        {slides.map((slide, index) => {
          const active = index === activeIndex;
          return (
            <Feature
              key={slide.title}
              active={active}
              onClick={() => setActiveIndex(index)}>
              <SVG
                svgAsset={slide.icon}
                width='100%'
                height='40px'
              />
              <Paragraph active={active}>
                {slide.title}
              </Paragraph>
            </Feature>
          )
        })}
      </FeaturesRow>
    </Wrapper>
  ) : null
}

FeatureCarousel.propTypes = {
  delay: PropTypes.number,
  slides: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
  }))
}

FeatureCarousel.defaultProps = {
  delay: 6000,
  slides: [
    {
      title: 'Laser Precision',
      description: 'Cut, Engrave, & Score',
      icon: '', // should be set as an URL for real deal

    }
  ]
}

export default FeatureCarousel;
