import React from 'react';
import PropTypes from 'prop-types';
import BlogCard from './blog-article-card';

const BlogWrapper = ({ attributes, articles }) => {
  const allArticles = articles?.map(({article}) => {
    return{
      data:{
        author: article?.value?.data?.author,
        categories: article?.value?.data?.categories,
        date: article?.value?.data?.date,
        image: article?.value?.data?.image,
        slug: article?.value?.data?.slug,
        summary: article?.value?.data?.summary,
        title: article?.value?.data?.title,
      }
    }
  })

  return (
    <>
      {allArticles?.length > 0 &&
        allArticles[0]?.data?.title != undefined &&
        allArticles?.map((article,index) => {
          return <BlogCard {...attributes} article={article} key={article?.data?.slug + index} isInCarousel={true} />;
        })}
    </>
  );
};

BlogWrapper.propTypes = {
  attributes: PropTypes.shape({}),
  allArticles: PropTypes.arrayOf(PropTypes.shape({}))
};

BlogWrapper.defaultProps = {
  attributes: {},
  allArticles: [],
};

export default BlogWrapper;
