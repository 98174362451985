import styled, { createGlobalStyle } from 'styled-components';

const WistiaModalEmbed = styled('div')`
  display: inline-block;
  position: relative;
  cursor: pointer;
`;

export const WistiaModalGlobalStyle = createGlobalStyle`
  /* Dont allow any scrolling when video is shown */
  .wistia_popover_mode {
    overflow: hidden;
  }

  /* prevent wistia popover_embed from overflowing onload. 
    this is a bug with Wistia's code. */
  .wistia_popover_embed,
  div[class^='wistia'],
  div[id^='wistia'],
  div[id^='wistia'][id$='_popover'],
  div[id$='_popover'] {
    max-width: 100%;
  }
`;

export default WistiaModalEmbed;
