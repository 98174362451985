import React from 'react';
import PropTypes from 'prop-types';
import FooterRebrand from './footer';

const FooterWrapper = ({ includeFooter, footerRebrandContent }) =>
  includeFooter ? <FooterRebrand footerContent={footerRebrandContent} /> : null;

FooterWrapper.propTypes = {
  includeFooter: PropTypes.bool,
  footerRebrandContent: PropTypes.shape({}).isRequired,
};

FooterWrapper.defaultProps = {
  includeFooter: true,
};

export default FooterWrapper;
