import {
  NavigationLinkWrapper,
  NavigationIconWrapper,
  DropdownIcon,
  CategoryText,
} from '../../blog-categories-navigation.styles';
import SVG from '@glowforge/gf-styled/src/components/svg/svg';
import { Image as BuilderImage } from '@builder.io/react';
const NavigationLink = ({ category, isSubcategories }) => {
  return (
    <NavigationLinkWrapper className='navigation-link-wrapper'>
      {category?.image && (
        <NavigationIconWrapper>
          <BuilderImage
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            lazy='false'
            image={category?.image}
          />
        </NavigationIconWrapper>
      )}
      {category?.name && <CategoryText>{category?.name}</CategoryText>}
      {isSubcategories && (
        <DropdownIcon className='icon'>
          <SVG
            id='chevron-down'
            fill='#000'
            stroke='#000'
            width={10}
            height={10}
          />
        </DropdownIcon>
      )}
    </NavigationLinkWrapper>
  );
};

export default NavigationLink;
