import styled from 'styled-components';
import Sections from '../contentful-views/sections';
import Markdown from '../markdown';
import Link from '../link';
import LinkStyle from '../link/link-base'
import { getTypographyVariant, Paragraph } from '../typography';

const LinkList = styled('nav')`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 16px;

  > ${LinkStyle} {
    margin: 4px 0;
    --font-weight: 500;
  }
`;

const SectionHeader = styled(Paragraph)`
  color: ${props => props.theme.colors.slate.lightest};
  margin-bottom: 8px;
  align-self: flex-start;
`;

const Grouping = styled('div')`
  width: 50%;

  @media ${props => props.theme.queries.desktopAndUp} {
    min-width: 15%;
    max-width: 20%;
    padding-right: 16px;
  }
`;

const Sales = styled('div')`
  display: none;

  @media ${props => props.theme.queries.desktopAndUp} {
    display: inherit;
  }
`;

const SocialLink = styled(Link)`
// Temporary. Once SVGs are also using styled components,
// this should be updated to reflect that pattern and remove
// the class name. 
// TODO: GROW-139
width: 44px;
&:not(:first-child) {
  margin-left: 12px;
}
> .button-icon-wrapper {
  margin-left: 0;
  > div {
    justify-content: center;
  }

  svg {
    height: 100%;
    max-height: 44px;
    width: 100%;
    max-width: 44px;
  }
}
`;

const SocialIconWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  justify-content: flex-start;

  @media ${props => props.theme.queries.desktopAndUp} {
    justify-content: flex-end;
  }
`;

const SocialWrapper = styled('div')`
  order: -1;
  width: 100%;
  padding: 0 0 32px 0;
  display: flex;
  flex-direction: column;
  
  > ${SectionHeader} {
    display: none;
  }

  @media ${props => props.theme.queries.desktopAndUp} {
    align-items: flex-end;
    margin-left: auto;
    order: unset;
    width: unset;
    flex: 1;
    padding: 0;

    > ${SectionHeader} {
      display: inherit;
    }
  }
`;

const LinkWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 48px;
`;

const LoveWrapper = styled(Sections)`
  display: flex;
  justify-content: end;
  align-items: center;

  ${getTypographyVariant('body')}

  && img {
    margin: 0 4px;
    width: 16px;
  }
`;

const CopyRightTitle = styled(Markdown)`
  && p {font-size: 0.75rem;}
`;

const LegalLink = styled(Link)`
  font-size: 0.75rem;
`;

const Divider = styled('div')`
  border-left: 2px solid ${props => props.theme.colors.slate.lightest};
  padding: 0;
  margin: 2px 6px;
`;

const LegalLinkWrapper = styled('div')`
  display: flex;
`;

const LegalLinkSection = styled('nav')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const AdditionalInfoRow = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;

  @media ${props => props.theme.queries.desktopAndUp} {
    flex-direction: row;
  }
`;

const LogoRow = styled(AdditionalInfoRow)`
  align-items: center;

  // Remove after refactoring SVG to allow passing of width/height.
  // TODO: GROW-139
  && span {
    svg {
      width: 124px;
      height: auto;
    }
  }
`;

const CopyRightRow = styled(AdditionalInfoRow)`
  align-items: center;

  @media ${props => props.theme.queries.desktopAndUp} {
    align-items: baseline;
  }
`;

const AdditionalInfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${props => props.theme.colors.slate.lightest};
  padding-top: 16px;
`;

const Wrapper = styled('div')`
  grid-column: main-start / main-end;
`;

export {
  AdditionalInfoWrapper,
  CopyRightRow,
  CopyRightTitle,
  Divider,
  Grouping,
  LegalLink,
  LegalLinkSection,
  LegalLinkWrapper,
  LinkWrapper,
  LinkList,
  LogoRow,
  LoveWrapper,
  Sales,
  SectionHeader,
  SocialWrapper,
  SocialIconWrapper,
  SocialLink,
  Wrapper,
};
