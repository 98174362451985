import styled, { keyframes } from 'styled-components';
import Button from '@glowforge/gf-styled/src/components/button';

const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

const ResultsContainer = styled('div')`
    padding: 50px 20px;
    background-color: ${props => props.isFound ? "#FFFF" : '#f0f0f0'};
    @media ${(props) => props.theme.queries.desktopDown} {
        padding: 30px 25px 25px 25px;
    }
`

const NothingFoundWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`
const ResultsTitle = styled('h1')`
    font-size: 41px;
    line-height: 100%;
    text-align: center;
    font-family: 'Space Grotesk';
    margin-bottom: ${props => props?.notFound ? '0' : '20px'};
    margin-top: ${props => props?.notFound ? '50px' : '0'};
    @media ${(props) => props.theme.queries.desktopDown} {
        font-size: 32px;
        margin-top: ${props => props?.notFound ? '30px' : '0'};
    }
`

const SearchDescription = styled('p')`
    text-align: center;
    font-size: 18px;
`

const SearchWrapper = styled('div')`
    width: 100%;
    margin: 0 auto;
    max-width: 300px;
    position: relative;
`

const SearchInput = styled('input')`
  width: 100%;
  border: none;
  width: 100%;
  background-color: #FFFFFF;
  padding: 10px 45px 10px 20px;
  height: 40px;
  border: 1px solid #bebebe;
  border-radius: 50px;
  &::placeholder {
  color: #bebebe;
  user-select: none;
  font-size: 20px;
}
`
const SearchIconButton = styled('button')`
    border: 0;
    padding: 10px 20px 10px 15px;
    background: transparent;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
`

const FoundArticlesWrapper = styled('div')`
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    row-gap: 50px;
`

const ArticlesWrapper = styled('div')`
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
`

const BlogArticlesWrapper = styled('div')`
    width: 100%;
    display: grid;
    padding-top: 50px;
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
    row-gap: 50px;
    column-gap: 30px;
    margin: 0 auto;
    justify-content: center; /* Centers the grid container horizontally */
    align-items: center; /* Centers the grid container vertically */
    @media ${(props) => props.theme.queries.desktopDown} {
        padding-top: 30px;
    }
`

const LoadMoreBtn = styled(Button)`
    width: fit-content;
    margin: 0 auto;
    margin-top: 50px;
    cursor: pointer;
    display: ${props => props.hasMore ? 'flex' : 'none'};
    min-width: 120px;
    min-height: 40px;
`

const DotsContainer = styled('div')`
    display: flex;
    width: 100%;
    justify-content: center;
    & > span {
      animation: ${blink} 1s infinite;
      &:nth-child(2) {
        animation-delay: 0.33s;
      }
      &:nth-child(3) {
        animation-delay: 0.66s;
      }
`
export {
    ResultsContainer,
    NothingFoundWrapper,
    ResultsTitle,
    SearchDescription,
    SearchWrapper,
    SearchInput,
    SearchIconButton,
    FoundArticlesWrapper,
    BlogArticlesWrapper,
    ArticlesWrapper,
    LoadMoreBtn,
    DotsContainer
}