import React, { useCallback, useRef, useState } from 'react';
import SVG from '@glowforge/gf-styled/src/components/svg/svg';
import useOutsideClick from '../../../../../gf-styled/src/hooks/useOutsideClick';
import { useRouter } from 'next/router';
import { blogSearch } from '../../../util/blogSearch'
import {
  SearchContainer,
  SearchOpener,
  SearchBarContainer,
  SearchInputWrapper,
  SearchInput,
  SearchSubmitBtn,
  SearchCloseBtn,
} from '../../blog-categories-navigation.styles';

const SearchBar = ({ setExpanded }) => {
  const [expandedSearch, setExpandedSearch] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const router = useRouter();

  const handleSubmit = (e) => {
    const formattedValue = blogSearch(e,inputValue);
    if(formattedValue?.length > 0){
      router.push(`/blog/search?q=${formattedValue}`);
      setExpanded && setExpanded(false);
      setExpandedSearch(false);
      setInputValue('');
    }
  };

  const toggleMenu = useCallback((e) => {
    e.stopPropagation();
    setExpandedSearch((state) => !state);
  }, [expandedSearch]);

  const closeMenu = useCallback(() => {
    if (expandedSearch) {
      setExpandedSearch(false);
      setInputValue('');
    }
  }, [expandedSearch]);

  const ref = useRef(null);
  useOutsideClick(closeMenu, [], ref);

  return (
    <SearchContainer ref={ref}>
      <SearchOpener
        className='search-opener-wrapper'
        expanded={expandedSearch}
        onClick={toggleMenu}>
        <SVG
          id='blog-search'
          fill='#000'
          stroke='none'
          width={20}
          height={20}
        />
      </SearchOpener>

      <SearchBarContainer className='search-bar-wrapper' expanded={expandedSearch}>
        <SearchInputWrapper>
          <SearchSubmitBtn onClick={handleSubmit}>
            <SVG
              id='blog-search'
              fill='#bebebe'
              stroke='none'
              width={20}
              height={20}
            />
          </SearchSubmitBtn>
          <SearchInput
            required
            type='text'
            placeholder='Search...'
            id='search-text'
            name='search-text'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleSubmit}
          />
          <SearchCloseBtn onClick={closeMenu}>
            <SVG
              id='blog-search-close'
              fill='#bebebe'
              stroke='none'
              width={15}
              height={15}
            />
          </SearchCloseBtn>
        </SearchInputWrapper>
      </SearchBarContainer>
    </SearchContainer>
  );
};

export default SearchBar;
