import { removeCookie } from '../../util/cookies';
import {
  buildShopifyPrinterUrl,
  getAffiliateInfo,
  parseReferralCookie,
  setShareProgramCookie,
} from './shareProgramUtils';

export const shareProgramActions = {
  API_INITIALIZE: 'API_INITIALIZE',
  GET_REFERRAL_COOKIE: 'GET_REFERRAL_COOKIE',
};

function updateAffiliateInfo() {
  // Returns empty object if no affiliate info
  const affiliateInfo = getAffiliateInfo();
  if (affiliateInfo?.code) {
    if (!affiliateInfo?.hasCookie) {
      setShareProgramCookie(
        'affiliate',
        `${affiliateInfo?.name}`,
        `${affiliateInfo?.code}`,
        {params: `${affiliateInfo?.params}`},
      );
    }
    removeCookie('referral');
    return affiliateInfo;
  }
  return undefined;
}

function updateReferralInfo(state, payload) {
  if (payload?.code) {
    const shopUrl = buildShopifyPrinterUrl(
      payload.parameters?.fbuy,
      payload.parameters?.fbshare,
      payload.code
    );

    // If we have a payload for new referral,
    // there is no case where affiliate info wins
    removeCookie('affiliate');
    setShareProgramCookie(
      'referral',
      `${payload.name}`,
      `${payload.code}`,
      payload.parameters || {},
    );
    return {
      ...state,
      fetching: false,
      referralInfo: {
        ...payload,
        shopUrl,
      },
      affiliateInfo: undefined,
    };
  }

  return {
    ...state,
    fetching: false,
    affiliateInfo: updateAffiliateInfo(),
  };
}

export function shareProgramReducer(state, action) {
  switch(action.type) {
    case shareProgramActions.GET_REFERRAL_COOKIE:
      return {
        ...state,
        fetching: false,
        referralInfo: parseReferralCookie(action.payload),
      };
    case shareProgramActions.API_INITIALIZE:
        return updateReferralInfo(state, action.payload);
    default:
      return state;
  }
}
