import styled from 'styled-components';

export const WistiaResponsivePadding169 = styled('div')`
  padding: 56.25% 0 0 0;
  position: relative;
`;

export const WistiaResponsivePadding1to1 = styled('div')`
  padding: 100% 0 0 0;
  position: relative;
`;
export const WistiaResponsivePaddingVertical = styled('div')`
  padding: 177.71% 0 0 0;
  position: relative;
`;

export const WistiaReponsivePadding100 = styled('div')`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const WistiaResponsiveWrapper = styled('div')`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  /* prevent autoPlayingSilent video from having Wistia controls */
  ${(props) =>
    props.silentAutoPlay && props.noInteract
      ? `
      & ::after {
        content: '';
        position: absolute;
        inset: 0;
        display: block;
      }
      `
      : ''}
`;

export const WistiaEmbed = styled('div')`
  height: 100%;
  position: relative;
  width: 100%;
`;

export const WistiaSwatch = styled('div')`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 200ms;
  width: 100%;
`;

export const WistiaImg = styled('img')`
  filter: blur(5px);
  height: 100%;
  object-fit: ${props => props.cover ? 'cover' : 'contain'};
  width: 100%;
`;
