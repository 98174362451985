
// Shared Regex
export const wistiaRegex = /https?:\/\/(?:.+)?(?:wistia\.com|wi\.st)\/(?:medias|embed)\/(.*)/;

// Wisitia medias link like https://glowforge.wistia.com/medias/pv3pj3pvc7
export function parseWistiaLink(wistiaLink) {
  const groups = wistiaLink.match(wistiaRegex);
  return groups && groups[1];
}

/*
  Registers the embed into the _wq object
  This is picked up by the video-analytics.jsx code in dotcom
*/
/* eslint-disable no-underscore-dangle */
export function registerEmbed(mediaId, options) {
  window._wq = window._wq || [];
  window?._wq.push({
    id: mediaId,
    async: true,
    onReady: () => {}, // video object can be used
    ...options
  });
}
/* eslint-enable no-underscore-dangle */