import {
  wistiaRegex
} from '../util/wistia-shared'

export default {
  name: 'Glowforge:WistiaModal',
  image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F947b9620ea56474086fe8c0461fc89db?quality=60&width=200&height=200',
  inputs: [
    {
      friendlyName: 'Wistia Popover/Modal',
      helperText: 'Copy the url from the Wistia. ex: https://glowforge.wistia.com/medias/pv3pj3pvc7',
      name: 'wistiaLink',
      type: 'string',
      defaultValue: '',
      regex: {
        // pattern to test, like "^\/[a-z]$" 
        pattern: wistiaRegex,
        // flags for the RegExp constructor, e.g. "gi"  */
        options: "g",
        // message to display to end-users if the regex fails
        message: "You must use a media or embed URL from wistia"
      }
    },
  ],
}