import {
  SubcategoryText,
  MobileInnerDropdown,
} from '../../blog-categories-navigation.styles';
import Link from '@glowforge/gf-styled/src/components/link';

const Dropdown = ({ opened, subcategories }) => {
  return (
    <MobileInnerDropdown opened={opened} className='mobile-inner-dropdown'>
      {subcategories?.map(({subcategory}, index) => {
        const { name, slug } = subcategory?.value?.data || {};
        return (
          name && slug && (
            <Link key={slug + name + index} href={`/blog/categories/${slug}`} className="navigation-link-wrapper">
              <SubcategoryText>{name}</SubcategoryText>
            </Link>
          )
        );
      })}
    </MobileInnerDropdown>
  );
};

export default Dropdown;
