import styled from 'styled-components';
import {
  Subheading as UnstyledSubheading,
  Paragraph as UnstyledParagraph,
} from '@glowforge/gf-styled/src/components/typography';

export const Wrapper = styled('div')`
  width: 100%;
`

export const CarouselWrapper = styled('div')`
  @media ${(props) => props.theme.queries.desktopAndUp} {
    display:block;
    width: 80%;
    margin: 0 auto;
  }
`

export const Subheading = styled(UnstyledSubheading)`
  margin-top: 2rem;
  text-align: center;
  width: 100%;
  min-height: 100px;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    min-height: auto;
  }
`

export const FeaturesRow  = styled('div')`
  display:flex;
  width: 100%;
  margin-top: 2rem;
  overflow-x: scroll;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    overflow-x: hidden;
    justify-content: space-evenly;
  }
`

export const Feature = styled('div')`
  color: ${(props) => props.active ? props.theme.colors.magic.base : 'black'};
  cursor: pointer;
  margin-right: 30px;
  min-width: 100px;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    margin-right: 0;
  }

  svg {
    fill: ${(props) => props.active ? props.theme.colors.magic.base : 'black'};
    stroke: ${(props) => props.active ? props.theme.colors.magic.base : 'black'};

    @media ${(props) => props.theme.queries.desktopAndUp} {
      height: 60px;
    }
  }
`

export const Paragraph = styled(UnstyledParagraph)`
  text-align: center;
  color: ${(props) => props.active ? props.theme.colors.magic.base : 'black'}; 
`

export default {
  Wrapper,
  Subheading,
  FeaturesRow,
  Feature,
  Paragraph
}
