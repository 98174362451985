import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Video from './video.styles';
import useIntersectionObserver from '../util/useInterSectionObserver';

const InlineVideo = ({ mp4Src, webmSrc, thumbnailSrc, attributes, autoplay}) => {
  const ref = useRef(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  return (
  <Video
    ref={ref}
    poster={thumbnailSrc}
    playsInline
    autoPlay={autoplay}
    loop='loop'
    muted
    width='100%'
    height='100%'
    {...attributes}>
    {isVisible ? (
      <>
        {mp4Src ? <source src={mp4Src} type='video/mp4' /> : null}
        {webmSrc ? <source src={webmSrc} type='video/mp4' /> : null}
      </>
    ): null}
  </Video>
)};

InlineVideo.propTypes = {
  attributes: PropTypes.shape({}),
  mp4Src: PropTypes.string,
  thumbnailSrc: PropTypes.string,
  webmSrc: PropTypes.string,
  autoplay: PropTypes.bool,
};

InlineVideo.defaultProps = {
  attributes: {},
  mp4Src: '',
  thumbnailSrc: '',
  webmSrc: '',
  autoplay: true
};

export default InlineVideo;
