import { getContentType } from '../page';

/**
 * @param {object} data
 * 
 * @returns {object}
 */
export const getContentfulGlobalFooterProps = data => {
  const content = data?.fields?.content ?? [];
  let startingProps = {
    globalFooterContact: null,
    globalFooterLegal: null,
    globalFooterLinks: [],
    globalFooterLogo: null,
    globalFooterSales: null,
    globalFooterSignature: null,
    globalFooterSocialRebrand: null
  }

  const props = content?.reduce?.((acc, obj) => {
    const fields = obj.fields;
    switch(fields?.id) {
      case 'contact-info': {
        acc.globalFooterContact = fields
        break
      }
      case 'legal-info-intl':
      case 'legal-info': {
        acc.globalFooterLegal = fields
        break
      }
      case 'sales-info': {
        acc.globalFooterSales = fields
        break
      }
      case 'glowforge-signature': {
        acc.globalFooterSignature = fields
        break
      }
      case 'social-links-rebrand': {
        acc.globalFooterSocialRebrand = fields
        break
      }
      case 'product-links':
      case 'company-links': {
        const { title, id, listItems } = fields
        const items = listItems?.filter(item => getContentType(item) === 'buttonLink')
        const formattedLinks = {
          title,
          id,
          items
        }
        acc.globalFooterLinks.push(formattedLinks)
        break
      }
      case 'glowforge-footer-logo': {
        acc.globalFooterLogo = fields.svgAsset ?? null
        break
      }
      default: {
        break
      }
    }
    return acc
  }, startingProps);

  return props;
 };
