import { useEffect } from 'react';
import { clientOnly } from '../../util';

const VIDEO_COMPLETE = 0.9; /* Percent at which video is considered complete */
const VIDEO_PLAYBACK_STARTED = 'Video Playback Started';
const VIDEO_PAUSED = 'Video Paused';
const VIDEO_SEEK = 'Video Seek Completed'
const VIDEO_PLAYBACK_COMPLETED = 'Video Playback Completed';
const VIDEO_PLAYBACK_COMPLETED_100 = 'Video Playback 100% Completed';
const VIDEO_CONVERSION = 'Video Lead Captured'
const WISTIA = 'Wistia';

const track = (event, video_provider, video, additional) => { /* eslint-disable-line camelcase */
  clientOnly(() => {
    if (window.analytics) {
      /* eslint camelcase: ["error", {"allow": ["video_*"]}] */
      window.analytics.track(event, {
        video_provider,
        video_title: video.name(),
        video_id: video.hashedId(),
        video_length: video.duration(),
        video_event_key: video.eventKey(),
        ...additional
      });
    }
  });
};

const useWistiaVideoTracking = () => {
  useEffect(() => {
    const handler = {
      id: '_all',
      onReady: (video) => {
        video.bind('play', () => {
          track(VIDEO_PLAYBACK_STARTED, WISTIA, video);
          const allVideos = window.Wistia.api.all();
          allVideos.forEach(item => {
            if (item.hashedId() !== video.hashedId()) {
              item.pause();
            }
          });
        });
        video.bind('percentwatchedchanged', (percent, lastPercent) => {
          if (percent >= VIDEO_COMPLETE && lastPercent < VIDEO_COMPLETE) {
            track(VIDEO_PLAYBACK_COMPLETED, WISTIA, video);
          }
        });
        video.bind('end', () => {
          track(VIDEO_PLAYBACK_COMPLETED_100, WISTIA, video);
        })
        video.bind("pause", () => {
          track(VIDEO_PAUSED, WISTIA, video);
        })
        video.bind('seek', (time) => {
          const additional = {
            video_position: time
          }
          track(VIDEO_SEEK, WISTIA, video, additional)
        })
        video.bind("conversion", (type, email, firstName, lastName) => {
          /* eslint camelcase: ["error", {"allow": ["first_name", "last_name", "video_*"]}] */
          window.analytics.identify({
            email, 
            first_name: firstName, 
            last_name: lastName
          })
          const additional = {
            video_conversion_type: type,
            video_conversion_email: email, 
            video_conversion_first_name: firstName, 
            video_conversion_last_name: lastName
          }
          track(VIDEO_CONVERSION, WISTIA, video, additional);
        })
      },
    };
    clientOnly(() => {
      /*
       * window._wq is provided by the Wistia Player.
       * https://wistia.com/support/developers/player-api
       */
      /* eslint-disable no-underscore-dangle */
      window._wq = window._wq || [];
      window._wq.push(handler);
    });
    return () => {
      window._wq.splice(window._wq.indexOf(handler), 1);
      /* eslint-enable no-underscore-dangle */
    };
  }, []);
};

const VideoAnalytics = () => {
  useWistiaVideoTracking();
  return null;
};

export default VideoAnalytics;
