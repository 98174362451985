import styled from 'styled-components';
import { m } from 'framer-motion';
import {
  Heading,
  Paragraph,
} from '@glowforge/gf-styled/src/components/typography';

const Wrapper = styled('div')`
  display: flex;
  min-height: 405px;
  justify-content: center;
  position:relative;
  column-gap: 60px;
  justify-content: space-between;
  @media ${(props) => props.theme.queries.desktopDown} {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;
  }
`;

const ButtonsContainer = styled('div')`
    overflow-x: hidden;
    min-width: 240px;
`
const ButtonsWrapper = styled('div')`
    display:flex;
    flex-direction: column;
    row-gap: 8px;
    min-width: 240px;
    overflow-x: hidden;

    @media ${(props) => props.theme.queries.desktopDown} {
      flex-direction: row;
      column-gap: 6px;
      overflow-x: auto;
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
      display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
`;

const ImgTextContainer = styled('div')`
    width: 100%;
    transform: translateY(${(props) => (props.isSectionVisible ? 0 : "10%")});
    opacity: ${(props) => (props.isSectionVisible ? '1' : '0')};
    transition: all 0.5s ease;
    @media ${(props) => props.theme.queries.desktopAndUp} {
      max-height: 420px;
    }
`
const ImgTextWrapper = styled(m.div)`
  width: 100%;
  height:100%;
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);

  @media ${(props) => props.theme.queries.desktopAndUp} {
    display:flex;
    justify-content: space-between;
    padding-right: 10px;
    overflow: hidden;
    align-items: center;
  }
    
    @media ${(props) => props.theme.queries.desktopDown} {
      display: grid;
      padding-top: 30px;
      grid-template-areas:'block0'
                          'block1';
      flex-direction: column-reverse;
      will-change: opacity;
      transition: flex-direction 0s;
    }
`;

const ButtonWrapper = styled('div')`
    width: 100%;
    @media ${(props) => props.theme.queries.desktopAndUp} {
      opacity: ${(props) => (props.isSectionVisible ? '1' : '0')};
      transition: 1s ease;
      ${props => props.index && `transition-delay: ${props.index * 0.06}s`};
      transform: translateX(${(props) => (props.isSectionVisible ? '0' : '-300%')});
  }
`
const Button = styled('button')`
    width: 100%;
    background: ${(props) => props.active ? props.theme.colors.magic.base : 'transparent'};
    color: ${(props) => (props.active ? 'white' : props.theme.colors.slate.base)};
    font-family: 'Space Grotesk';
    font-weight: 500;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    white-space: nowrap;
    padding: 7px 14px 7px 13px;
    border: 1px solid ${(props) => props.active ? props.theme.colors.magic.base : '#E3E3E3'};
    border-radius: 6px;
   
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    &:hover {
      background: ${(props) => props.active ? props.theme.colors.magic.buttonBase : '#27b8ce2b'};
    }

    &:after {
      ${(props) => props.active ? 'content: ""' : ''};
      display: inline-block;
      width: 19px; 
      height: 19px; 
      background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6539 4.79415L11.9256 5.52246C11.7492 5.69884 11.7521 5.98573 11.932 6.15851L14.9274 9.03516H2.13281C1.88689 9.03516 1.6875 9.23454 1.6875 9.48047V10.5195C1.6875 10.7655 1.88689 10.9648 2.13281 10.9648H14.9274L11.932 13.8415C11.7521 14.0143 11.7492 14.3012 11.9256 14.4775L12.6539 15.2059C12.8278 15.3797 13.1098 15.3797 13.2837 15.2059L18.1747 10.3149C18.3486 10.141 18.3486 9.85902 18.1747 9.68509L13.2836 4.79415C13.1097 4.62025 12.8278 4.62025 12.6539 4.79415Z" fill="white"/>
      </svg>
      ');
      background-repeat: no-repeat;
      background-position: center;
      align-self: center;
   }

   @media ${(props) => props.theme.queries.desktopDown} {
    &:after {
      ${(props) => props.active ? 'content: ""' : ''};
      width: 15px; 
      height: 16px; 
      background-image: url('data:image/svg+xml;utf8,<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.38382 9.59517L8.77051 9.02019C8.62198 8.88094 8.38039 8.88322 8.23489 9.02525L5.81245 11.39L5.81245 1.28906C5.81245 1.09491 5.64454 0.9375 5.43745 0.9375L4.56245 0.9375C4.35536 0.9375 4.18745 1.09491 4.18745 1.28906L4.18745 11.39L1.76501 9.02528C1.61951 8.88322 1.37792 8.88094 1.22939 9.02021L0.616075 9.59519C0.469637 9.73248 0.469637 9.95508 0.616075 10.0924L4.73479 13.9537C4.88123 14.091 5.11867 14.091 5.26514 13.9537L9.38382 10.0923C9.53026 9.95505 9.53026 9.73245 9.38382 9.59517Z" fill="white"/>
      </svg>');
      margin-left: 6px;
   }
  }

`
const ImgContainer = styled(m.div)`
    width: 100%;
    height: 100%;
    img,
    picture {
      width: 100%;
      height: 100%;
      object-fit: cover; 
      object-position: center; 
   }
   @media ${(props) => props.theme.queries.desktopDown} {
    grid-area: block1;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
   }
`
const TitleDescriptionWrapper = styled('div')`
   width: 100%;
   padding-left: 60px;
   display:flex;
   flex-direction: column;
   row-gap: 10px;
   @media ${(props) => props.theme.queries.desktopDown} {
    grid-area: block0;
    margin-bottom: 25px;
    width: 100%;
    padding: 0 15px;
   }
`

const Title = styled(Heading)`
   font-size: 34px;
   line-height: 1.2;
   font-family: 'Space Grotesk';
   font-weight: 500;
   @media ${(props) => props.theme.queries.desktopDown} {
    text-align: center;
    font-size: 30px;
   }
`
const DescriptionDesktop = styled(Paragraph)`
   font-size: 19px;
   line-height: 1.4;
   font-weight: 500;
   font-family: 'Space Grotesk';
   max-width: 370px;
   @media ${(props) => props.theme.queries.desktopDown} {
      display: none;
   }
`

const DescriptionMobile = styled(Paragraph)`
   display:none;
   @media ${(props) => props.theme.queries.desktopDown} {
    display: block;
    max-width: 100%;
    text-align: center;
    font-size: 17px;
 }
`


const SelectFeatures = styled('div')`
  display: none;
  @media ${(props) => props.theme.queries.desktopDown} {
    display: block;
    margin: 0 auto;
    font-size: 14px;
    font-family: 'Space Grotesk', sans-serif;
  }
`;


export {
  Wrapper,
  ButtonsWrapper,
  ButtonsContainer,
  ImgTextWrapper,
  ButtonWrapper,
  Button,
  TitleDescriptionWrapper,
  ImgContainer,
  Title,
  DescriptionDesktop,
  DescriptionMobile,
  ImgTextContainer,
  SelectFeatures
};
