import React from 'react';
import PropTypes from 'prop-types';
// needs button link to be registered if not included on gf-styled page?
import '@glowforge/gf-styled/src/contentful-widgets/button-link';
import Footer from '@glowforge/gf-styled/src/components/footer/footer';
import { getContentfulGlobalFooterProps } from '@glowforge/gf-styled/src/models/footer/footer';

import FooterWrapper from './footer.styles';

const FooterSection = ({ footerContent }) => (
  <FooterWrapper>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Footer {...getContentfulGlobalFooterProps(footerContent)} />
  </FooterWrapper>
);

FooterSection.propTypes = {
  footerContent: PropTypes.shape({}).isRequired,
};

export default FooterSection;
