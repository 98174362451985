import styled from 'styled-components';
import {
    Title,
    Paragraph
} from '@glowforge/gf-styled/src/components/typography';

import Link from '@glowforge/gf-styled/src/components/link';

const HeroContainer = styled('section')`
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: center;
    height: 80vh;
    width: 100%;

    .blog-hero-read-more{
        width: fit-content;
    }
`

const HeroMainWrapper = styled('div')`
    display: flex;
    .swiper-pagination{
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px 10px;
        border-radius: 10px;
        display: flex;
        background: #fff;
    }
    .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
        border: 1px solid #26b8ce;
        border-radius: 50%;
        background-color: #fff;
        transition: all 0.25s ease-in-out;
        padding: 0px;
    }
     .swiper-pagination-bullet.swiper-pagination-bullet-active{
        background: #26b8ce;
     }
`
const BackgroundImageContainer = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${props => `url(${props?.desktopImage})`};
    background-position: ${props => props.backgroundPositionDesktop};
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    pointer-events: none;
    z-index: -2;
    
   @media ${(props) => props.theme.queries.desktopDown} {
    background-image: ${props => `url(${props?.mobileImage})`};
    background-position: ${props => props?.backgroundPositionMobile};
   }
`

const HeroContentContainer = styled('div')`
   display: flex;
   width: fit-content;
   height: 100%;
   padding: 20px;
   margin: 0 auto;
`

const HeroContentWrapper = styled('div')`
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 0 120px;
   max-width: 1200px;
   @media ${(props) => props.theme.queries.desktopDown} {
     padding: 0; 
     .blog-hero-read-more{
        font-size: 14px;
     }
   }
`

const CategoryText = styled('p')`
    font-size: 32px;
    letter-spacing: -0.09px;
    line-height: 145%;
    color: #fff;
`

const HeroTitle = styled('h1')`
    font-size: 68px;
    line-height: 120%;
    text-align: left;
    color: ${props => props.textColor == 'black' ? "#12151A": "#fff"};
    text-align: ${props => props.isCategoryTitle && 'center'};
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 500;
    letter-spacing: -3.4px;
    padding: 15px 0;
    @media ${(props) => props.theme.queries.desktopDown} {
        font-size: 38px;
        line-height: 120%;
    }
`

const AuthorDateWrapper = styled('div')`
    display: flex;
    justify-content: flex-end;
     @media ${(props) => props.theme.queries.desktopDown} {
        margin-bottom: ${props => props.isMain ? '10px' : '0'};
     {
`
const DateText = styled('p')`
    font-size: 18px;
    color: #fff;
    letter-spacing: -0.09px;
    font-family: 'Exo 2', sans-serif;
    color: ${props => props.textColor == 'black' ? "#12151A": "#fff"};

    &:after {
        content: "|";
        display: inline-block;
        margin: 0 10px;
        font-size: 18px;
    }
     @media ${(props) => props.theme.queries.desktopDown} {
        font-size: 13px;
        &:after {
        font-size: 13px;
    }
    }
`
const AuthorLink = styled(Link)`
    font-family: 'Exo 2', sans-serif;
    font-size: 18px;
    color: ${props => props.textColor == 'black' ? "#12151A": "#fff"};
    font-weight: 400;
     @media ${(props) => props.theme.queries.desktopDown} {
        font-size: 13px;
    }
`
const HeroDescription = styled('p')`
    font-size: 18px;
    letter-spacing: -0.09px;
    line-height: 150%;
    text-align: left;
    color: ${props => !props.isAuthor ? props.textColor == 'black' ? "#12151A": "#fff" : "#12151A"};
    max-width: 800px;
    margin-bottom: ${props => (props.isAuthor & !props.isImage) ? '0' : '20px'};
    padding-left: ${props => !props.isAuthor ? '4px' : '0'};
     @media ${(props) => props.theme.queries.desktopDown} {
        font-size: 15px;
        line-height: 120%;
        margin-bottom: ${props => props.isAuthor && "0px"};
        text-align: ${props => props.isAuthor ? "center" : "left"};
        display: ${props => !props.isAuthor && 'none'};
    }
`

const HeroDescriptionMobile = styled('p')`
     display: none;
     color: ${props => props.isAuthor ? "#12151A" : "#fff"};
     @media ${(props) => props.theme.queries.desktopDown} {
        font-size: 14px;
        max-width: 500px;
        margin-bottom: 20px;
        line-height: 120%;
        display: block;
        text-align: ${props => props.isAuthor ? "center" : "left"};
        padding-left: ${props => !props.isAuthor ? '2px' : '0'};
    }
`

const AuthorHeroContainer = styled('section')`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    height: 80vh;
    z-index: 0;
    padding: 50px 20px;
    justify-content: ${(props) => props.isDesc ? 'start' : 'center'};
    background-color: #f0f0f0;
    background-image: ${props => `url(${props?.backgroundImageDesktop})`};
    background-position: ${props => props.backgroundPositionDesktop};
    background-repeat: no-repeat;
    background-size: cover;
    @media ${(props) => props.theme.queries.desktopDown} {
        height: 100%;
        padding-top: 20px;
        background-image: ${props => `url(${props?.backgroundImageMobile})`};
        background-position: ${props => props.backgroundPositionMobile};

    }
`

const AuthorHeroWrapper = styled('div')`
    display: flex;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: #fff;
    border-top-left-radius: ${props => props.isImage ? '100px' : '0'};
    border-bottom-left-radius: ${props => props.isImage ? '100px' : '0'};
    padding: ${props => props.isImage ? '5px' : '30px'};
    gap: 65px;
    align-items: center;
    margin-top: 40px;
    display: ${props => props.isDesc ? 'flex': 'none'};
    @media ${(props) => props.theme.queries.desktopDown} {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        flex-direction: column;
        align-items: center;
        padding: 35px;
        gap: 35px;
        margin-top: 20px;
    }

`

const AuthorImageWrapper = styled('div')`
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 50%;
    overflow: hidden;
    width: 198px;
    height: 198px;

    img, picture {
        width: 100%;
        height: 100%;
    }

    @media ${(props) => props.theme.queries.desktopDown} {
        width: 150px;
        height: 150px;
    }
`

const AuthorDescriptionWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
`



export {
    HeroContainer,
    AuthorHeroContainer,
    BackgroundImageContainer,
    HeroContentContainer,
    HeroContentWrapper,
    CategoryText,
    HeroTitle,
    HeroDescription,
    AuthorHeroWrapper,
    AuthorImageWrapper,
    AuthorDescriptionWrapper,
    HeroMainWrapper,
    AuthorDateWrapper,
    DateText,
    AuthorLink,
    HeroDescriptionMobile

};
