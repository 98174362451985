import React from 'react';

const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.101" height="19.101" viewBox="0 0 19.101 19.101">
  <g>
    <g>
      <path d="M9.551,0A9.551,9.551,0,1,0,19.1,9.551,9.579,9.579,0,0,0,9.551,0ZM7.641,13.848v-8.6l5.73,4.3-5.73,4.3Z" fill="#fff" fillRule="evenodd"/>
    </g>
  </g>
</svg>
)

export default PlayIcon