
export default {
  name: 'FeatureCarousel',
  friendlyName: 'Feature Carousel',
  noWrap: true,
  description: 'A feature carousel section that uses auto playing video',
  image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F8fbbb3351b2945b1a5299f15706834df?quality=60&width=200&height=200',
  inputs: [
    {
      type: 'number',
      name: 'delay',
      defaultValue: 6000,
      helperText:
        'This is in milliseconds. The transition delay between carousel items.',
    },
    {
      type: 'list',
      name: 'slides',
      subFields: [
        {
          name: 'title',
          type: 'string',
          helperText: 'Title under the icon',
          defaultValue: 'Title',
        },
        {
          name: 'description',
          type: 'string',
          helperText: 'Description under the video',
          defaultValue: 'Description',
        },
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['svg'],
          helperText: 'Icon above the slide title',
          defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Ff6a46eee139b485b919f51948ccb78e3',
        },
        {
          name: 'wistiaLink',
          type: 'string',
          helperText: 'Wistia Embed link',
          defaultValue: 'https://glowforge.wistia.com/medias/pv3pj3pvc7',
        }
      ],
      defaultValue: [
        {
          title: 'Title',
          description: 'Description',
          // Below value needs updating.  Temporary for now.
          icon: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Ff6a46eee139b485b919f51948ccb78e3',
          wistiaLink: 'https://glowforge.wistia.com/medias/pv3pj3pvc7',
        },
        {
          title: 'Title',
          description: 'Description',
          // Below value needs updating.  Temporary for now.
          icon: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Ff6a46eee139b485b919f51948ccb78e3',
          wistiaLink: 'https://glowforge.wistia.com/medias/pv3pj3pvc7',
        },
      ]
    }
  ]
}
