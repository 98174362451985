import {
  ArticleWrapper,
  ImgContainer,
  ContentContainer,
  ArticleHeading,
  ArticleSumarry,
  ReadMoreButton,
  DateAuthorWrapper,
  GreyText,
  CategoriesWrapper,
  CategoryWrapper,
  CardInfoWrapper,
  CategoriesText,
} from './blog-articles.styles';

import { dateConverter } from '../util/dateConverter';

import Link from '@glowforge/gf-styled/src/components/link';
import { Image as BuilderImage } from '@builder.io/react';
import PropTypes from 'prop-types';

const BlogCard = ({ article, isInCarousel }) => {
  let { summary, title, slug } = article?.data || {};
  if (summary && summary.length > 220) {
    summary = summary.substring(0, 220) + '...';
  }

  return (
    title &&
    slug && (
      <ArticleWrapper className='article-wrapper' isInCarousel={isInCarousel}>
        {article?.data?.image && (
          <ImgContainer className='article-img-container'>
            <Link href={`/blog/article/${slug}`}>
              <BuilderImage
                style={{
                  height: '100%',
                  width: '100%',
                }}
                lazy={true}
                image={article?.data?.image}
              />
            </Link>
          </ImgContainer>
        )}
        <ContentContainer className='article-content-container'>
          <CardInfoWrapper>
            {article?.data?.categories?.length > 0 && (
              <CategoriesWrapper
                className={`categories-wrapper categories-${slug}`}>
                {article?.data?.categories?.map((x, index) => {
                  return (
                    <CategoryWrapper
                      key={x?.blogCategory?.value?.data?.slug + index}>
                      {x?.blogCategory?.value?.data?.slug &&
                        x?.blogCategory?.value?.data?.name && (
                          <Link
                            href={`/blog/categories/${x?.blogCategory?.value?.data?.slug}`}>
                            <CategoriesText>
                              {x?.blogCategory?.value?.data?.name +
                                (article?.data?.categories.length - 1 > index
                                  ? ', '
                                  : '')}
                            </CategoriesText>
                          </Link>
                        )}
                    </CategoryWrapper>
                  );
                })}
              </CategoriesWrapper>
            )}
            <Link
              href={`/blog/article/${article?.data?.slug}`}
              className='article-link-title'>
              <ArticleHeading>{title}</ArticleHeading>
            </Link>

            {summary && (
              <ArticleSumarry className='article-link-summary'>
                {summary}
              </ArticleSumarry>
            )}
          </CardInfoWrapper>

          <CardInfoWrapper>
            <DateAuthorWrapper className='article-date-author-wrapper'>
              {article?.data?.date && (
                <GreyText className='article-date'>
                  {dateConverter(article?.data?.date)}
                </GreyText>
              )}
              {article?.data?.author?.value?.data?.name && (
                <Link
                  href={`/blog/author/${article?.data?.author?.value?.data?.slug}`}
                  className='article-author-link'>
                  <GreyText isAuthor={true}>
                    {article?.data?.author?.value?.data?.name}
                  </GreyText>
                </Link>
              )}
            </DateAuthorWrapper>
            <Link
              href={`/blog/article/${slug}`}
              className='read-more-btn'>
              <ReadMoreButton className='blog-article-read-more-btn'>
                Read More
              </ReadMoreButton>
            </Link>
          </CardInfoWrapper>
        </ContentContainer>
      </ArticleWrapper>
    )
  );
};

export default BlogCard;

BlogCard.propTypes = {
  article: PropTypes.shape({}),
  isInCarousel: PropTypes.bool,
};

BlogCard.defaultProps = {
  article: {},
  isInCarousel: false,
};
