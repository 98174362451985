import {
  DesktopNavigation,
  DesktopNavigationWrapper,
  DesktopCategoryNavigationContainer,
} from '../../blog-categories-navigation.styles';
import NavigationLink from './navigation-link';
import Dropdown from './dropdown';
import Link from '@glowforge/gf-styled/src/components/link';
import SearchBar from '../search/search-bar';
const DesktopNav = ({ categories, homeLink }) => {
  return (
    <DesktopNavigation className='desktop-navigation'>
      <DesktopNavigationWrapper className='desktop-navigation-wrapper'>
        <DesktopCategoryNavigationContainer className='all-categories-navigation-container'>
          {homeLink?.link && homeLink && (
            <Link href={`${homeLink?.link}`}>
              <NavigationLink category={homeLink} />
            </Link>
          )}
        </DesktopCategoryNavigationContainer>
        {categories?.map((category) => {
          const isSubcategories = category?.subcategories?.[0]?.hasOwnProperty('subcategory') ?? false;
          return (
            <DesktopCategoryNavigationContainer
              className='category-navigation-container'
              key={category?.slug + category?.name}>
              <Link href={`/blog/categories/${category?.slug}`}>
                <NavigationLink
                  category={category}
                  isSubcategories={isSubcategories}
                />
                {isSubcategories && <Dropdown category={category} />}
              </Link>
            </DesktopCategoryNavigationContainer>
          );
        })}
      </DesktopNavigationWrapper>
      <SearchBar />
    </DesktopNavigation>
  );
};

export default DesktopNav;
