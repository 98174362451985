export default {
    name: 'Glowforge:BlogCategoriesNavigation',
    friendlyName: 'Blog Categories Navigation',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F7564d9458d8848809045763fe43f3dbe?width=200&height=200',
    noWrap: true,
    inputs: [
        {
            name: 'homeLink',
            friendlyName: 'Home Link',
            type: 'object',
            subFields: [
                {
                    name: 'name',
                    friendlyName: 'Name',
                    helperText: `Title of All categories (that's a link to the main blog page)`,
                    type: 'text',
                },
                {
                    name: 'link',
                    friendlyName: 'Link',
                    type: 'text',
                },
                {
                    name: 'image',
                    friendlyName: 'Image',
                    type: 'file',
                },
            ],
        },
        {
            name: 'mobileDropdownOpenerText',
            friendlyName: 'Mobile Dropdown Opener Text',
            type: 'text',
            defaultValue: "Categories"
        },
        {
            name: 'categories',
            friendlyName: 'Categories',
            helperText:
                `These are references to Blog-categories Sections.
          To add a new Category, create a new Blog Category -https://builder.io/content?model=53f1deb61b3a4fbfaaf2aea6cbdb18f1`,
            type: 'list',
            subFields: [
                {
                    name: 'category',
                    type: 'reference',
                    model: 'category',
                },
                {
                    name: 'subcategories',
                    helperText: 'Add items only if this category is a main one.',
                    type: 'list',
                    subFields: [
                        {
                            name: 'subcategory',
                            type: 'reference',
                            model: 'category',
                        }

                    ]
                },
                {
                    name: 'image',
                    type: 'file',
                    allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp']
                },
            ],

        },

    ],
};
