import React from 'react';

import SVG from '../svg';

import { getSectionsArray } from '../contentful-views/sections';
import VisuallyHidden from '../visually-hidden';

import {
  CopyRightRow,
  CopyRightTitle,
  Divider,
  Grouping,
  LegalLinkWrapper,
  LegalLinkSection,
  LegalLink,
  LinkList,
  SectionHeader,
  SocialWrapper,
  SocialIconWrapper,
  SocialLink,
} from './footer.styles';

function showOsanoDrawer(e) {
  e.preventDefault();
  Osano?.cm?.showDrawer('osano-cm-dom-info-dialog-open');
}

const LinkGrouping = ({ content }) => {
  return content?.map((group, index) => (
    <Grouping key={index}>
      <SectionHeader>{group.title}</SectionHeader>
      <LinkList aria-labelledby={`${group.title}-footer-menu`}>
        <VisuallyHidden id={`${group.title}-footer-menu`}>
          {group.title} links
        </VisuallyHidden>
        {getSectionsArray(group.items)}
      </LinkList>
      {/* <LinkList sections={group.items}/> */}
    </Grouping>
  )) || null;
};

const ContactSection = ({ content }) => (
  <section>
    <SectionHeader>{content?.title}</SectionHeader>
    <LinkList as='div'>
      {getSectionsArray(content?.listItems)}
    </LinkList>
  </section>
);

const SocialIcon = ({ icon }) => {
  if (!icon) return null;
  const svgAsset = icon?.fields?.svgAsset;
  if (!svgAsset) return null;
  return <SVG svgAsset={svgAsset} />;
}

const SocialSection = ({ content }) => (
  <SocialWrapper>
    <nav aria-labelledby={`${content?.title}-section`}>
      <SectionHeader id={`${content?.title}-section`}>{content?.title}</SectionHeader>
      <SocialIconWrapper iconCount={content?.listItems?.length}>
        {content?.listItems?.map((link) => (
        <SocialLink
          key={link?.fields?.id}
          className='social-item-link'
          href={link?.fields?.url}
          target={link?.fields?.target}
          rel={link?.fields?.rel}
          aria-label={`External link to Glowforge's ${link?.fields?.text} page`}
          icon={<SocialIcon icon={link?.fields?.icon} />}>
          {!link?.fields?.icon ? link?.fields?.text : ''}
        </SocialLink>
      )) || null}
      </SocialIconWrapper>
    </nav>
  </SocialWrapper>
);

const LegalSection = ({ content }) => {
  const privacyPreferencesId = "footer-legal-link-privacy-preferences";
  return (
  <CopyRightRow>
    <CopyRightTitle source={content?.title}/>
    <LegalLinkSection linkCount={content?.listItems?.length} aria-labelledby='footer-legal-links-section'>
      {content?.listItems?.map((link, index) => (
        <LegalLinkWrapper key={link?.fields?.id}>
          { link?.fields?.id == privacyPreferencesId ?
            <LegalLink
              href=''
              onClick={showOsanoDrawer}
              variant='legal'>
              {link?.fields?.text}
            </LegalLink> :
            <LegalLink
              href={link?.fields?.url}
              rel={link?.fields?.rel}
              target={link?.fields?.target}
              variant='legal'>
              {link?.fields?.text}
            </LegalLink>
          }
          {index !== content?.listItems?.length - 1 
            ? <Divider /> : null}
        </LegalLinkWrapper>
      )) || null}
      <VisuallyHidden id='footer-legal-links-section'>Legal Links</VisuallyHidden>
    </LegalLinkSection>
  </CopyRightRow>
)};

export {
  ContactSection,
  LegalSection,
  LinkGrouping,
  SocialSection,
};
